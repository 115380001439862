import { ArrowBack } from "@material-ui/icons";
import {
  AppBar,
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  styled as MuiStyled,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Button, Color, useSnackbarStack } from "@superdispatch/ui";
import { uniqueId } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import * as analytics from "shared/utils/analytics";
import { useResponsive } from "shared/utils/useResponsive";
import { useSignatureToken } from "shared/utils/useSignatureToken";
import styled from "styled-components";

import { SignatureConfirmedPage } from "./core/SignatureConfirmedPage";
import { TermsAndConditionsDialog } from "./core/TermsAndConditionsDialog";
import { sendTouchlessSignature } from "./data/OnlineBOLAPI";

const Wrapper = MuiStyled("div")(({ theme }) => ({
  boxSizing: "border-box",
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",

  [`${theme.breakpoints.up("xs")}`]: {
    padding: 8,
  },

  [`${theme.breakpoints.up("sm")}`]: {
    padding: 32,
  },
}));

const ContainerCard = styled(Card)`
  max-width: 640px;
`;

const SignaturePlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: ${Color.Dark100};
  pointer-events: none;
  user-select: none;
`;

const SignatureDisabler = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Color.White50};
  z-index: 2;
`;

const CanvasWrapper = MuiStyled(Card)(({ theme }) => ({
  boxSizing: "border-box",
  display: "block",
  width: "100%",
  margin: "auto",
  border: "1px solid #e1e5ea",
  position: "relative",

  [`${theme.breakpoints.up("xs")}`]: {
    height: 166,
  },

  [`${theme.breakpoints.up("sm")}`]: {
    height: 320,
  },

  "& canvas": {
    position: "relative",
    zIndex: 1,
    width: "100%",
    height: "100%",
  },
}));

const ClearButton = MuiStyled(Button)(({ theme }) => ({
  [`${theme.breakpoints.down("sm")}`]: {
    padding: "10px 24px",
  },
}));

interface SignaturePageProps {
  loadId: string;
  terms: string;
  orderStatus: string | null;
}

export function SignaturePage({
  loadId,
  terms,
  orderStatus,
}: SignaturePageProps) {
  const uid = useMemo(() => uniqueId("snackbar"), []);
  const { addSnackbar } = useSnackbarStack();
  const r = useResponsive();
  const match = useRouteMatch<{ id: string }>();
  const history = useHistory();
  const { search } = useLocation();
  const signatureToken = useSignatureToken(search);
  const [signatoryName, setSignatoryName] = useState("");
  const [signatureCanvasRef, setRef] = useState<SignatureCanvas | null>();
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [isSignatureSent, setIsSignatureSent] = useState(false);
  const [isSendingSignature, setIsSendingSignature] = useState(false);
  const [loadedAt] = useState(Date.now());
  const [isTermsVisible, setIsTermsVisible] = useState(false);

  const saveSignature = () => {
    if (signatureCanvasRef && !signatureCanvasRef.isEmpty()) {
      signatureCanvasRef.getTrimmedCanvas().toBlob((blob) => {
        if (signatureToken && blob) {
          setIsSendingSignature(true);
          sendTouchlessSignature({
            id: match.params.id,
            file: blob,
            signatoryName,
            signatureToken,
          }).then(
            () => {
              addSnackbar("Successfully signed!", {
                variant: "success",
                key: uid,
              });
              setIsSignatureSent(true);
              setIsSendingSignature(false);
              analytics.track('Clicked "Save Signature"', {
                duration: Math.round((Date.now() - loadedAt) / 1000),
                order_status: orderStatus,
              });
            },
            () => {
              addSnackbar("Error: Couldn't sign the document.", {
                variant: "error",
                key: uid,
              });
              setIsSendingSignature(false);
            },
          );
        } else {
          addSnackbar("Something went wrong", {
            variant: "error",
            key: uid,
          });
        }
      });
    } else {
      addSnackbar("There is no signature!", {
        variant: "error",
        key: uid,
      });
      setIsCanvasEmpty(true);
    }
  };

  useEffect(() => {
    if (signatureCanvasRef) {
      const canvas = signatureCanvasRef.getCanvas();
      let width = window.innerWidth;

      // Adjust canvas coordinate space taking into account pixel ratio,
      // to make it look crisp on mobile devices.
      // This also causes canvas to be cleared.
      const resizeCanvas = () => {
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        const ctx = canvas.getContext("2d");

        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;

        ctx && ctx.scale(ratio, ratio);
        signatureCanvasRef && signatureCanvasRef.clear();
        setIsCanvasEmpty(true);
      };

      // Ignoring height resizes to prevent clearing the canvas
      // Chrome mobile hides navigation bar on scroll
      // which fires widow resize event
      const listener = (event: UIEvent) => {
        if ((event.target as Window).innerWidth !== width) {
          width = (event.target as Window).innerWidth;
          resizeCanvas();
        }
      };

      // ajdust canvas size on initialization
      resizeCanvas();

      window.addEventListener("resize", listener);

      return () => {
        window.removeEventListener("resize", listener);
      };
    }
  }, [signatureCanvasRef]);

  return (
    <>
      <TermsAndConditionsDialog
        open={isTermsVisible}
        onClose={() => {
          setIsTermsVisible(false);
        }}
      >
        {terms}
      </TermsAndConditionsDialog>
      <AppBar position="sticky">
        <Toolbar>
          {!isSignatureSent && (
            <IconButton
              edge="start"
              onClick={() => {
                history.push({
                  pathname: `/${match.params.id}`,
                  search: history.location.search,
                });
              }}
              size="medium"
            >
              <ArrowBack htmlColor={Color.Dark300} />
            </IconButton>
          )}
          <Typography variant="h2">Add Signature</Typography>
        </Toolbar>
      </AppBar>

      <Wrapper>
        {isSignatureSent ? (
          <SignatureConfirmedPage
            BOLlink={`/${match.params.id}`}
            loadId={loadId}
          />
        ) : (
          <ContainerCard>
            <CardContent>
              <Grid container={true} direction="column" spacing={2}>
                <Grid aria-label="Load ID" item={true}>
                  {loadId ? (
                    <>
                      <Typography
                        component="span"
                        color="textSecondary"
                        gutterBottom={true}
                      >
                        Load ID:{" "}
                      </Typography>
                      <Typography component="span" gutterBottom={true}>
                        {loadId}
                      </Typography>
                    </>
                  ) : (
                    <Typography color="textSecondary" gutterBottom={true}>
                      Load ID is not available
                    </Typography>
                  )}
                </Grid>
                <Grid item={true}>
                  <Typography variant={r({ xs: "h4", sm: "h3" })}>
                    Enter your full name and add your signature
                  </Typography>
                </Grid>
                <Grid item={true} sm={8}>
                  <TextField
                    id="full-name"
                    label="Full Name"
                    fullWidth={true}
                    disabled={isSendingSignature}
                    value={signatoryName}
                    onChange={({ target }) => {
                      setSignatoryName(target.value);
                    }}
                  />
                </Grid>
                <Grid item={true}>
                  <CanvasWrapper>
                    {isCanvasEmpty && (
                      <SignaturePlaceholder>
                        <Typography
                          variant={r({ xs: "h3", sm: "h4" })}
                          align="center"
                        >
                          Draw your signature here
                        </Typography>
                      </SignaturePlaceholder>
                    )}

                    {isSendingSignature && <SignatureDisabler />}

                    <SignatureCanvas
                      clearOnResize={false}
                      onBegin={() => {
                        setIsCanvasEmpty(false);
                      }}
                      onEnd={() => {
                        setIsCanvasEmpty(
                          !!(
                            signatureCanvasRef && signatureCanvasRef.isEmpty()
                          ),
                        );
                      }}
                      ref={(ref) => {
                        if (ref && !signatureCanvasRef) {
                          setRef(ref);
                        }
                      }}
                    />
                  </CanvasWrapper>
                </Grid>
                <Grid item={true}>
                  <Grid
                    container={true}
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <Grid item={true}>
                      <ClearButton
                        disabled={isCanvasEmpty || isSendingSignature}
                        fullWidth={true}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          if (signatureCanvasRef) {
                            signatureCanvasRef.clear();
                            setIsCanvasEmpty(true);
                          }
                        }}
                      >
                        Clear
                      </ClearButton>
                    </Grid>
                    <Grid item={true} xs={true} sm="auto">
                      <Button
                        disabled={isCanvasEmpty || !signatoryName}
                        isLoading={isSendingSignature}
                        fullWidth={true}
                        variant="contained"
                        onClick={saveSignature}
                      >
                        Save Signature
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true} />
                <Grid item={true}>
                  {!terms ? (
                    <Typography color="textSecondary">
                      I agree with the Driver’s assessment of the condition of
                      this vehicle(s) condition report.
                    </Typography>
                  ) : (
                    <Typography color="textSecondary">
                      I agree with the Driver’s assessment of the condition of
                      this vehicle(s) condition report. I have read and agree
                      with{" "}
                      <Link
                        color="primary"
                        component="button"
                        onClick={() => {
                          setIsTermsVisible(true);
                        }}
                      >
                        Terms and Conditions
                      </Link>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </ContainerCard>
        )}
      </Wrapper>
    </>
  );
}
