import { SvgIcon, SvgIconProps } from "@material-ui/core";

export function SignatureConfirmedIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 100 144" {...props}>
      <path
        d="M101.462 109.169V20.9231C93.8308 13.2923 89.4 8.86154 81.7693 1.23077H6.69236C3.98467 1.23077 1.76929 3.44616 1.76929 6.15385V131.2C1.76929 133.908 3.98467 136.123 6.69236 136.123H80.2924"
        fill="white"
      />
      <path
        d="M101.462 109.169V20.9231C93.8308 13.2923 89.4001 8.86154 81.7693 1.23077H6.69236C3.98467 1.23077 1.76929 3.44616 1.76929 6.15385V131.2C1.76929 133.908 3.98467 136.123 6.69236 136.123H80.2924"
        stroke="#C4CDD5"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9539 122.585H58.3846"
        stroke="#E6E6E6"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M13.9539 13.0462H51.3692"
        stroke="#E6E6E6"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M14.5692 105.6C14.5692 105.6 21.0923 100.185 26.6308 100.923C34.7539 102.031 22.2 123.323 14.5692 124.8C6.81539 126.4 27.6154 109.538 33.0308 110.277C35.2462 110.523 31.9231 115.692 34.1385 116.431C36.3539 117.169 39.5539 110.277 41.7692 111.754C43.4923 112.985 41.7692 119.508 42.8769 119.508C43.9846 119.508 51.6154 109.538 56.0462 112.615"
        stroke="#222F44"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9539 20.4308H71.9231"
        stroke="#E6E6E6"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M92.2308 11.6923L81.7693 1.23077V16C81.7693 18.7077 83.9847 20.9231 86.6924 20.9231H101.462L92.2308 11.6923Z"
        fill="#C4CDD5"
        stroke="#C4CDD5"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.4307 83.5692H14.6923C14.6923 83.5692 14.6923 74.8308 14.6923 66.0923C14.6923 57.3538 18.1384 58.3385 21.9538 51.5692C27.7384 41.3539 29.2153 36.9231 36.4769 36.9231C43.7384 36.9231 53.9538 36.9231 53.9538 36.9231"
        stroke="#222F44"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.4769 83.5692H88.9077C88.9077 83.5692 88.9077 74.8308 88.9077 66.0923C88.9077 57.3538 85.4615 58.3385 81.6462 51.5692C75.8615 41.3539 74.3846 36.9231 67.1231 36.9231C59.8615 36.9231 52.6 36.9231 52.6 36.9231"
        stroke="#222F44"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.7077 90.8309H16.4615C15.1077 90.8309 14 89.7232 14 88.3693V83.5693H27.1692V88.3693C27.1692 89.7232 26.0615 90.8309 24.7077 90.8309Z"
        fill="#222F44"
      />
      <path
        d="M86.7077 90.8309H78.4615C77.1077 90.8309 76 89.7232 76 88.3693V83.5693H89.1692V88.3693C89.1692 89.7232 88.0615 90.8309 86.7077 90.8309Z"
        fill="#222F44"
      />
      <path
        d="M68.4769 61.7846H35V70.5231H68.4769V61.7846Z"
        fill="#222F44"
        stroke="#222F44"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.1077 79.2616H21.5846C20.9692 79.2616 20.4769 78.7693 20.4769 78.1539V77.5385C20.4769 76.9231 20.9692 76.4308 21.5846 76.4308H28.1077C28.7231 76.4308 29.2154 76.9231 29.2154 77.5385V78.1539C29.2154 78.7693 28.7231 79.2616 28.1077 79.2616Z"
        fill="#222F44"
      />
      <path
        d="M82.0154 79.2616H75.4923C74.877 79.2616 74.3846 78.7693 74.3846 78.1539V77.5385C74.3846 76.9231 74.877 76.4308 75.4923 76.4308H82.0154C82.6308 76.4308 83.1231 76.9231 83.1231 77.5385V78.1539C83.1231 78.7693 82.6308 79.2616 82.0154 79.2616Z"
        fill="#222F44"
      />
      <path
        d="M35 70.5231H25.1539C22.5692 70.5231 20.4769 68.4308 20.4769 65.8461V61.7846H35V70.5231Z"
        stroke="#222F44"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9538 53.0462H17.5231C15.9231 53.0462 14.5692 51.6923 14.5692 50.0923V49.8462C14.5692 48.2462 15.9231 46.8923 17.5231 46.8923H23.3077"
        stroke="#222F44"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.6461 53.0462H86.0768C87.6768 53.0462 89.0307 51.6923 89.0307 50.0923V49.8462C89.0307 48.2462 87.6768 46.8923 86.0768 46.8923H80.2922"
        stroke="#222F44"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.4769 70.5231H78.3231C80.9077 70.5231 83 68.4308 83 65.8461V61.7846H68.4769V70.5231Z"
        stroke="#222F44"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9539 53.0462H81.6462"
        stroke="#222F44"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.1539 99.6923C100.723 99.6923 110.077 109.046 110.077 120.615C110.077 132.185 100.723 141.538 89.1539 141.538C77.5847 141.538 68.2308 132.185 68.2308 120.615C68.2308 109.046 77.5847 99.6923 89.1539 99.6923ZM89.1539 97.2308C76.2308 97.2308 65.7693 107.692 65.7693 120.615C65.7693 133.538 76.2308 144 89.1539 144C102.077 144 112.539 133.538 112.539 120.615C112.539 107.692 102.077 97.2308 89.1539 97.2308Z"
        fill="white"
      />
      <path
        d="M89.1539 141.538C100.709 141.538 110.077 132.171 110.077 120.615C110.077 109.06 100.709 99.6923 89.1539 99.6923C77.5984 99.6923 68.2308 109.06 68.2308 120.615C68.2308 132.171 77.5984 141.538 89.1539 141.538Z"
        fill="#1FA84D"
      />
      <path
        d="M86.4462 128.369L79.677 121.6L82.2616 118.892L86.4462 123.2L97.5232 112.123L100.108 114.831L86.4462 128.369Z"
        fill="#E6E6E6"
      />
    </SvgIcon>
  );
}
