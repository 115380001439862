import {
  FormattedDate,
  FormattedRelativeTime,
  NullableDateInput,
  parseDate,
  toPrimitiveDateInput,
} from "@superdispatch/dates";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { CST } from "shared/utils/formatDate";

export interface FormattedRelativeTimeProps {
  date: NullableDateInput;
}

export function FormattedRelativeDate({
  date: dateProp,
}: FormattedRelativeTimeProps) {
  const primitiveInput = toPrimitiveDateInput(dateProp);
  const date = useMemo(() => parseDate(primitiveInput), [primitiveInput]);
  const today = useMemo(() => DateTime.local(), []);

  if (date.hasSame(today, "day")) {
    return <FormattedRelativeTime date={date} />;
  }

  return <FormattedDate date={date} variant="Date" />;
}

export function useETADates(from: NullableDateInput, until: NullableDateInput) {
  return useMemo(() => {
    if (from == null || until == null) {
      return null;
    }

    const fromInput = toPrimitiveDateInput(from);
    const untilInput = toPrimitiveDateInput(until);

    const fromDate = parseDate(fromInput).setZone(CST);
    const untilDate = parseDate(untilInput).setZone(CST);

    let date = "";

    if (fromDate.hasSame(untilDate, "day")) {
      date += fromDate.toFormat("MMM d,");
    } else if (fromDate.hasSame(untilDate, "month")) {
      date += `${fromDate.toFormat("MMM d")} - ${untilDate.toFormat("d")},`;
    } else {
      date += `${fromDate.toFormat("MMM d")} - ${untilDate.toFormat("MMM d")},`;
    }

    const fromMeridiem = fromDate.toFormat("a");
    const untilMeridiem = untilDate.toFormat("a");
    const fromHour = fromDate.toFormat("h");
    const untilHour = untilDate.toFormat("h");
    if (fromMeridiem === untilMeridiem) {
      date += ` ${fromHour} - ${untilHour} ${fromMeridiem}`;
    } else {
      date += ` ${fromHour} ${fromMeridiem} - ${untilHour} ${untilMeridiem}`;
    }

    return `${date} CST`;
  }, [from, until]);
}
