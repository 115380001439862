import { Link, Print } from "@material-ui/icons";
import {
  AppBar,
  IconButton,
  SvgIcon,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Color, Inline, useCollapseBreakpoint } from "@superdispatch/ui";
import { Box, Button } from "@superdispatch/ui-lab";
import styled, { css } from "styled-components";

import { trackBolEvent } from "../analytics/BOLAnalytics";
import { useClipboard } from "../utils/useClipboard";

export const Wrapper = styled(AppBar)`
  @media print {
    border: none;
  }
  z-index: 999;
`;

export const HeaderBar = styled(Toolbar)(
  ({ theme }: { theme: Theme }) => css`
    padding: 10px 16px;

    ${theme.breakpoints.up("sm")} {
      padding: 16px 32px;
    }
  `,
);

export const CustomColumns = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 360px) {
    justify-content: center;
  }
`;

export const Logo = styled(SvgIcon)`
  color: #fe4a49;
  font-size: 32px;
`;

export const CopyButton = styled(Button)(
  ({ theme }: { theme: Theme }) => css`
    ${theme.breakpoints.down("sm")} {
      padding-left: 8px;
      padding-right: 8px;
    }

    @media print {
      display: none;
    }
  `,
);

export const PrintButtonIcon = styled(IconButton)(
  ({ theme }: { theme: Theme }) => css`
    ${theme.breakpoints.down("sm")} {
      svg {
        font-size: 24px;
      }
    }

    @media print {
      display: none;
    }
  `,
);

interface HeaderProps {
  hasPrintButton: boolean;
  onPrint: () => void;
}

export const Header = ({ onPrint, hasPrintButton }: HeaderProps) => {
  const isCollapsed = useCollapseBreakpoint("sm");
  const copy = useClipboard();

  const onCopy = () => {
    const copyLink = new URL(window.location.toString());
    copyLink.searchParams.set("utm_source", "Online BOL");
    copyLink.searchParams.set("utm_medium", "Copied Link");
    copy(copyLink.toString());
    trackBolEvent({ name: "Clicked Copy Online BOL Link" });
  };

  return (
    <Wrapper>
      <HeaderBar>
        <Box maxWidth="1112px" width="100%" margin="auto">
          <CustomColumns>
            <Inline
              noWrap={true}
              space={["xsmall", "small"]}
              verticalAlign="center"
            >
              <Logo viewBox="0 0 32 32">
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.89937 8.61527C6.7317 4.30252 11.2381 1.33333 15.9087 1.33333C20.641 1.33333 25.0755 4.26662 26.9181 8.61527C28.9865 13.487 27.2158 18.3587 24.2851 22.4304C22.0576 25.5329 19.4348 28.1893 16.5144 30.6406C16.4733 30.6816 16.4066 30.6713 16.3707 30.6303C16.1141 30.3454 15.8692 30.0469 15.624 29.7479C15.4719 29.5625 15.3197 29.377 15.1645 29.1944C15.1235 29.1534 15.1337 29.0867 15.1748 29.0508C17.7359 26.9021 20.0815 24.5689 22.1345 21.9279C24.0593 19.4459 25.7633 16.4921 25.7633 13.2613C25.7633 7.95374 21.2261 3.42048 15.8985 3.42048C10.5349 3.42048 6.03367 7.91271 6.03367 13.2613C6.03367 14.8921 6.72656 17.6356 10.0422 21.4356C11.3767 22.974 12.7317 24.1894 13.5016 24.8509C14.3074 24.1227 15.7599 22.7535 17.2021 21.1074C19.9378 17.9741 21.3852 15.2613 21.3852 13.2613C21.3852 10.2358 18.9216 7.77425 15.8882 7.77425C12.86 7.77425 10.3912 10.2358 10.3912 13.2613C10.3912 15.9678 12.7779 18.1219 14.6579 19.8185C14.7371 19.89 14.8153 19.9606 14.8925 20.0305C14.9335 20.0664 14.9336 20.133 14.8976 20.1741C14.4773 20.6401 14.0569 21.1011 13.6365 21.5621L13.635 21.5638C13.5991 21.6048 13.5324 21.6048 13.4913 21.5689C11.0944 19.3997 8.3074 16.7485 8.3074 13.2613C8.3074 9.23064 11.8694 5.68711 15.8985 5.68711C19.9994 5.68711 23.4895 9.1691 23.4895 13.2613C23.4895 15.81 21.9036 18.9228 18.7778 22.4971C17.1919 24.3073 15.4571 25.9637 13.6093 27.5073C13.5734 27.538 13.517 27.538 13.481 27.5073C11.7 26.0611 9.99088 24.5227 8.48191 22.7945C7.19363 21.3125 6.15685 19.8459 5.41263 18.4407C3.77021 15.3331 3.50844 11.8819 4.89937 8.61527ZM15.9086 10.0255C17.6947 10.0255 19.1524 11.4716 19.1524 13.2614C19.1524 15.0511 17.705 16.5023 15.9086 16.4972C14.1173 16.4972 12.6648 15.0511 12.6648 13.2614C12.6648 11.4716 14.1173 10.0255 15.9086 10.0255ZM14.4869 13.2614C14.4869 14.0408 15.1233 14.6767 15.9086 14.6767C16.6939 14.6767 17.3303 14.0408 17.3303 13.2614C17.3303 12.4819 16.6939 11.846 15.9086 11.846C15.1233 11.846 14.4869 12.4819 14.4869 13.2614Z"
                />
              </Logo>
              <Typography noWrap={true} variant="h2">
                Online BOL
              </Typography>
            </Inline>
            <Inline space={["small", "large"]} verticalAlign="center">
              <CopyButton
                onClick={onCopy}
                startIcon={<Link />}
                variant={isCollapsed ? "text" : "primary"}
              >
                Copy Link
              </CopyButton>
              {hasPrintButton && (
                <PrintButtonIcon
                  edge="end"
                  size="small"
                  aria-label="Print Button"
                  onClick={onPrint}
                >
                  <Print />
                </PrintButtonIcon>
              )}
            </Inline>
          </CustomColumns>
        </Box>
      </HeaderBar>
    </Wrapper>
  );
};
