import { mdiCalendarMonth } from "@mdi/js";
import { SvgIcon } from "@material-ui/core";
import { Color } from "@superdispatch/ui";

export interface CalendarMonthProps {
  color?: Color;
}

export function CalendarMonth({ color = Color.Dark400 }: CalendarMonthProps) {
  return (
    <SvgIcon>
      <path fill={color} d={mdiCalendarMonth} />
    </SvgIcon>
  );
}
