export function PlayIcon() {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.1751 13.3403C32.2528 14.5223 32.2528 17.4775 30.1751 18.6596L11.4751 29.2982C9.39729 30.4803 6.80007 29.0027 6.80007 26.6385L6.80007 5.36133C6.80007 2.99719 9.39729 1.51961 11.4751 2.70167L30.1751 13.3403Z"
        fill="#D9D9D9"
      />
    </svg>
  );
}
