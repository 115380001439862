import { z } from "zod";

export type PhotoDTO = z.infer<typeof photoSchema>;
export const photoSchema = z.object({
  damages_count: z.number(),
  url: z.string().nullable().default(""),
  type: z.enum(["pickup", "delivery"]),
  thumbnail_url: z.string().nullable().default(""),
  is_vehicle_detected: z.boolean().nullable().optional(),
  default_image: z.boolean().nullable().optional(),
  is_video: z.boolean().nullable().optional(),
  duration_in_seconds: z.number().nullable().optional(),
});
