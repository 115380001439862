import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { findLast } from "lodash";

export const useResponsive = () => {
  const theme = useTheme();

  const matches = {
    xs: useMediaQuery(theme.breakpoints.up("xs")),
    sm: useMediaQuery(theme.breakpoints.up("sm")),
    md: useMediaQuery(theme.breakpoints.up("md")),
    lg: useMediaQuery(theme.breakpoints.up("lg")),
    xl: useMediaQuery(theme.breakpoints.up("xl")),
  };

  // eslint-disable-next-line func-names
  return function <P>(responsiveValues: Record<string, P>) {
    const match = findLast(
      theme.breakpoints.keys,
      (breakpoint) =>
        matches[breakpoint] && responsiveValues[breakpoint] != null,
    );

    return match && responsiveValues[match];
  };
};
