import { Typography } from "@material-ui/core";
import { Inline } from "@superdispatch/ui";
import { EtaDateDTO } from "shared/dto/EtaDTO";

import { useETADates } from "../core/RelativeDates";

export interface ETATitleProps {
  eta: EtaDateDTO | null;
  type: "delivery" | "pickup";
}

export function ETATitle({ eta, type }: ETATitleProps) {
  const date = useETADates(eta?.from, eta?.until);
  return (
    <Inline space="xxsmall">
      Estimated {type} is{" "}
      <Typography color="inherit" variant="inherit">
        {date}
      </Typography>
    </Inline>
  );
}
