import { looseItemsSchema } from "./LooseItemsDTO";
import { photoSchema } from "./PhotoDTO";
import { z } from "zod";
import { videoSchema } from "./VideoDTO";

export type VehicleDTO = z.infer<typeof vehicleSchema>;
export const vehicleSchema = z.object({
  year: z.number().nullable(),
  make: z.string().nullable(),
  model: z.string().nullable(),
  color: z.string().nullable(),
  type: z.string().nullable(),
  vin: z.string().nullable(),
  lot_number: z.string().nullable(),
  odometer_reading: z.string().nullable(),
  delivery_odometer_reading: z.string().nullable().optional(),
  notes: z.string().nullable(),
  pickup_inspection_notes: z.string().nullable(),
  delivery_inspection_notes: z.string().nullable(),
  videos: z.array(videoSchema).optional(),
  photos: z.array(photoSchema),
  inspections: z.array(looseItemsSchema),
});
