import { Box, Typography } from "@material-ui/core";

export function NotFound() {
  return (
    <Box
      flex="1"
      display="flex"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      p={4}
    >
      <div>
        <Typography variant="h3">Oops! BOL Not Found</Typography>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <p>The BOL you are looking for doesn't exist.</p>
      </div>
    </Box>
  );
}
