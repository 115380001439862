import { z } from "zod";

export type LooseItemsDTO = z.infer<typeof looseItemsSchema>;
export const looseItemsSchema = z.object({
  type: z.enum(["pickup", "delivery"]),
  navigation_disk_present: z.boolean().nullable(),
  spare_tire_present: z.boolean().nullable(),
  cargo_cover_present: z.boolean().nullable(),
  plugin_charger_cable_present: z.boolean().nullable(),
  windscreen_present: z.boolean().nullable(),
  radio_present: z.boolean().nullable(),
  manuals_present: z.boolean().nullable(),
  headphones_present: z.boolean().nullable(),
  all_glasses_ok: z.boolean().nullable(),
  is_drivable: z.boolean().nullable(),
  title_present: z.boolean().nullable(),
  headrests_count: z.number().nullable(),
  remotes_count: z.number().nullable(),
  valet_keys_count: z.number().nullable(),
});
