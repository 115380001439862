import { capitalize } from "lodash";

const heapAppId = import.meta.env.VITE_APP_HEAP_APP_ID;

export const load = () => {
  if (heapAppId) {
    window?.heap?.load(heapAppId);
  }
};

export const track = (name: string, details: Record<string, unknown>) => {
  if (heapAppId) {
    window?.heap?.track(name, details);
  }
};

export function trackEvent(name: string, parameters: Record<string, unknown>) {
  track(name, {
    ...getUTMTags(),
    ...parameters,
  });
}

function getUTMTags() {
  const params = new URLSearchParams(window.location.search);
  const tags: Record<string, unknown> = {};

  Array.from(params.entries()).forEach(([key, value]) => {
    if (key.startsWith("utm_")) {
      tags[key] = capitalize(value);
    }
  });

  return tags;
}
