import {
  Card,
  CardContent,
  CardMedia,
  Theme,
  Typography,
} from "@material-ui/core";
import { FormattedDate } from "@superdispatch/dates";
import { Color, Inline, Stack } from "@superdispatch/ui";
import { useRouteMatch } from "react-router";
import { VenueDTO } from "shared/dto/VenueDTO";
import { joinStrings } from "shared/utils/strings";
import styled, { css } from "styled-components";

import { VenuePath } from "../core/VenuePath";
import { useDriverLocation } from "../data/ETAAPI";
import { ETAContainer } from "./ETAContainer";
import { Map } from "./Map";
import { CarrierDetails } from "./CarrierDetails";
import { useFlag } from "shared/utils/features";
import { StaticMap } from "./StaticMap";
import { useFeatures } from "shared/api/api";
import { MarkedStaticMap } from "./MarkedStaticMap";

const VenuesCardContainer = styled(CardContent)(
  ({ theme }: { theme: Theme }) => css`
    &,
    &:last-child {
      padding: 24px;

      ${theme.breakpoints.up("sm")} {
        padding: 24px 32px;
      }
    }
  `,
);

const LoadId = styled(Typography)`
  color: ${Color.Dark100};
`;

const Address = styled.address`
  font-style: normal;
`;

const Dates = styled(Typography)`
  color: ${Color.Dark200};
`;

const NoDataText = styled(Typography)`
  color: ${Color.Dark100};
  display: inline-block;
`;

export interface Props {
  data: VenueDTO;
  isAIAG?: boolean;
  isAIAGWithETA?: boolean;
}

export function LoadDetails({ data, isAIAG, isAIAGWithETA }: Props) {
  const match = useRouteMatch<{ id: string }>();
  const shouldFetchDriverLocation = data.status === "Picked up";
  const { data: driverLocation, isFetched: isDriverLocationFetched } =
    useDriverLocation(match.params.id, {
      enabled: shouldFetchDriverLocation,
    });

  const { isFetched: isFeaturesFetched } = useFeatures();
  const shouldShowCarrierInfo = useFlag("bol_carrier_info");
  const shouldShowStaticMap = useFlag("online_bol_static_map");
  const shouldShowStaticMapWithAddresses = useFlag(
    "online_bol_static_map_addresses",
  );

  const { load_id, pickup, delivery, carrier } = data;

  const isPickupLocationUnavailable =
    !pickup.city && !pickup.state && !pickup.zip;

  const isDeliveryLocationUnavailable =
    !delivery.city && !delivery.state && !delivery.zip;

  const pickupAddress = !isPickupLocationUnavailable
    ? joinStrings(data.pickup.city, data.pickup.state, data.pickup.zip)
    : null;

  const deliveryAddress = !isDeliveryLocationUnavailable
    ? joinStrings(data.delivery.city, data.delivery.state, data.delivery.zip)
    : null;

  const isCarrierDataAvailable =
    carrier?.company_type && carrier?.email && carrier?.name && carrier?.phone;

  const carrierInfoDetails = () => {
    if (!shouldShowCarrierInfo) {
      return null;
    } else if (isCarrierDataAvailable) {
      return <CarrierDetails {...carrier} />;
    }
    return <NoDataText>Carrier Information is not available </NoDataText>;
  };

  return (
    <Card>
      <CardMedia>
        {isFeaturesFetched && shouldShowStaticMapWithAddresses && (
          <MarkedStaticMap
            pickupAddress={pickupAddress}
            deliveryAddress={deliveryAddress}
            driverLocation={driverLocation?.driver}
            isDriverLocationFetched={
              isDriverLocationFetched || !shouldFetchDriverLocation // consider driver location request has ended if no request made
            }
          />
        )}

        {isFeaturesFetched &&
          !shouldShowStaticMapWithAddresses &&
          shouldShowStaticMap && (
            <StaticMap
              pickupAddress={pickupAddress}
              deliveryAddress={deliveryAddress}
              driverLocation={driverLocation?.driver}
              isDriverLocationFetched={
                isDriverLocationFetched || !shouldFetchDriverLocation // consider driver location request has ended if no request made
              }
            />
          )}

        {isFeaturesFetched && !shouldShowStaticMap && (
          <Map
            pickupAddress={pickupAddress}
            deliveryAddress={deliveryAddress}
            driverLocation={driverLocation?.driver}
          />
        )}
      </CardMedia>
      <VenuesCardContainer>
        <Stack space="large">
          {carrierInfoDetails()}
          <Stack space="xsmall">
            {load_id ? (
              <Inline space="xxsmall">
                <LoadId>Load ID </LoadId>
                <Typography>{load_id}</Typography>
              </Inline>
            ) : (
              <NoDataText>Load ID is not available </NoDataText>
            )}
            <VenuePath>
              <Stack space="xsmall">
                <Address>
                  {isPickupLocationUnavailable ? (
                    <NoDataText>Pickup location is not available </NoDataText>
                  ) : (
                    <Inline space="xxsmall">
                      <Typography>{pickupAddress}</Typography>
                      <Dates>
                        {pickup.scheduled_date && (
                          <FormattedDate
                            date={pickup.scheduled_date}
                            variant="Date"
                          />
                        )}
                      </Dates>
                    </Inline>
                  )}
                </Address>
                <Address>
                  {isDeliveryLocationUnavailable ? (
                    <NoDataText>Delivery location is not available </NoDataText>
                  ) : (
                    <Inline space="xxsmall">
                      <Typography>{deliveryAddress}</Typography>
                      <Dates>
                        {delivery.scheduled_date && (
                          <FormattedDate
                            date={delivery.scheduled_date}
                            variant="Date"
                          />
                        )}
                      </Dates>
                    </Inline>
                  )}
                </Address>
              </Stack>
            </VenuePath>
          </Stack>

          {(!isAIAG || isAIAGWithETA) && (
            <ETAContainer venue={data} driverLocation={driverLocation} />
          )}
        </Stack>
      </VenuesCardContainer>
    </Card>
  );
}
