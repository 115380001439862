import { carrierSchema } from "./CarrierDTO";
import { etaSchema } from "./EtaDTO";
import { z } from "zod";

export type LoadStatus = "New" | "Picked up" | "Delivered";

export const venueDetailsSchema = z.object({
  scheduled_date: z.string().nullable(),
  estimated_date: z.string().nullable().optional(),
  actual_date: z.string().nullable(),
  city: z.string().nullable(),
  state: z.string().nullable(),
  zip: z.string().nullable(),
  signature: z.string().nullable().optional(),
});

export type VenueDTO = z.infer<typeof venueSchema>;
export const venueSchema = z.object({
  load_id: z.string(),
  carrier: carrierSchema.optional(),
  pickup: venueDetailsSchema,
  delivery: venueDetailsSchema,
  pickup_signature_refused: z.boolean().nullable().optional(),
  delivery_signature_refused: z.boolean().nullable().optional(),
  delivery_subject_to_inspection: z.boolean().nullable().optional(),
  created_at: z.string(),
  eta: etaSchema,
  delivery_eta: etaSchema.optional().nullable(),
  status: z.custom<LoadStatus>(),
  delivery_driver_address: z.string().nullable(),
  pickup_driver_address: z.string().nullable(),
});
