import { PhotoDTO } from "shared/dto/PhotoDTO";
import { VideoDTO } from "shared/dto/VideoDTO";

export function getRenderPhotos(
  photos: PhotoDTO[],
  videos: VideoDTO[],
  type: "pickup" | "delivery",
  isVideoEnabled: boolean,
) {
  const sorted = sortPhotos(photos);
  const videoList = videos.map(parseVideo);

  const total = photos.length + videos.length;
  const pickupPhoto = type === "pickup" ? sorted.slice(0, 1) : [];
  const theRestPhotoList =
    type === "pickup" ? sorted.slice(1, 6) : sorted.slice(0, 6);
  const theLastPhotos =
    type === "pickup" ? sorted.slice(1, sorted.length) : sorted;

  if (videos.length > 0 && isVideoEnabled) {
    if (total < 7) {
      return [...pickupPhoto, ...videoList, ...theLastPhotos] as PhotoDTO[];
    }
    return [
      ...pickupPhoto,
      ...videoList.slice(0, 1),
      ...theRestPhotoList,
    ] as PhotoDTO[];
  }

  return sorted.slice(0, 7);
}

export function parseVideo(video: VideoDTO) {
  return {
    url: video.thumbnail_url,
    thumbnail_url: video.thumbnail_url,
    is_vehicle_detected: true,
    is_video: true,
    duration_in_seconds: video.duration_in_seconds,
  };
}

export function sortPhotos(photos: PhotoDTO[]) {
  const defaultImages: PhotoDTO[] = [];
  const requiredPhotos: PhotoDTO[] = [];
  const additionalPhotos: PhotoDTO[] = [];

  photos.forEach((photo) => {
    if (photo.default_image) {
      defaultImages.push(photo);
    } else if (typeof photo.is_vehicle_detected === "boolean") {
      requiredPhotos.push(photo);
    } else {
      additionalPhotos.push(photo);
    }
  });

  return [
    ...defaultImages,
    ...requiredPhotos.sort((a, b) => {
      return Number(!!a.is_vehicle_detected) - Number(!!b.is_vehicle_detected);
    }),
    ...additionalPhotos,
  ];
}
