import { Link } from "@material-ui/core";
import { Color } from "@superdispatch/ui";
import { round } from "lodash";
import { useMemo } from "react";
import styled from "styled-components";

import { FormattedRelativeDate } from "../core/RelativeDates";
import { DriverLocationDTO, SingleDriverLocationDTO } from "../data/ETADTO";
import { useMapboxReversePredictions } from "../../utils/mapbox";

export const GreyLink = styled(Link)`
  color: ${Color.Dark200};
`;

interface DriverLocationProps {
  driver: DriverLocationDTO | SingleDriverLocationDTO;
}

export function DriverLocation({ driver }: DriverLocationProps) {
  const { latitude, longitude, time } = driver.location;
  const { data: locationResponse } = useMapboxReversePredictions(
    longitude && latitude ? [longitude, latitude] : undefined,
  );

  const location = useMemo(() => {
    return locationResponse?.features[0]?.place_name;
  }, [locationResponse]);

  const gMapLink = useMemo(() => {
    if (!latitude || !longitude) {
      return null;
    }

    const lat = round(latitude, 2);
    const long = round(longitude, 2);

    return `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
  }, [latitude, longitude]);

  if (!gMapLink) {
    return null;
  }

  return (
    <>
      Near{" "}
      <GreyLink href={gMapLink} target="_blank" rel="noopener noreferrer">
        {location}
      </GreyLink>{" "}
      · updated <FormattedRelativeDate date={time} />
    </>
  );
}
