import { useSnackbarStack } from "@superdispatch/ui";
import clipboardCopy from "clipboard-copy";
import { useCallback } from "react";

export function useClipboard() {
  const { addSnackbar } = useSnackbarStack();

  return useCallback(
    (text: string) => {
      clipboardCopy(text)
        .then(() => {
          addSnackbar("Link is copied", { variant: "success" });
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          alert(text);
        });
    },
    [addSnackbar],
  );
}
