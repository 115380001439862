import styled from "styled-components";
import { Box } from "@superdispatch/ui-lab";
import { DriverLocationDTO, SingleDriverLocationDTO } from "../data/ETADTO";
import { useMapboxPredictions } from "../../utils/mapbox";
import { stringifySearchQuery } from "../../utils/urlUtils";
import { API_KEY, MarkerOptions, stringifyMarker } from "../../utils/map";
import { useMemo } from "react";
import { GeocodeFeature } from "@mapbox/mapbox-sdk/services/geocoding";

export const MapContainer = styled(Box)`
  width: 100%;
  aspect-ratio: 16/9;
`;

export interface MapProps {
  pickupAddress: string | null;
  deliveryAddress: string | null;
  driverLocation:
    | DriverLocationDTO
    | SingleDriverLocationDTO
    | undefined
    | null;
  isDriverLocationFetched: boolean;
}

export function StaticMap({
  pickupAddress,
  deliveryAddress,
  driverLocation,
  isDriverLocationFetched,
}: MapProps) {
  const { data: pickupLocation, isFetched: isPickupLocationFetched } =
    useMapboxPredictions(pickupAddress);
  const { data: deliveryLocation, isFetched: isDeliveryLocationFetched } =
    useMapboxPredictions(deliveryAddress);

  const allLocationsLoaded =
    (isPickupLocationFetched || !pickupAddress) &&
    (isDeliveryLocationFetched || !deliveryAddress) &&
    isDriverLocationFetched;

  const markers = useMemo(() => {
    if (!pickupLocation && !deliveryLocation && !driverLocation) {
      return undefined;
    }

    function getVenueMarker(location: GeocodeFeature, options: MarkerOptions) {
      const [longitude, latitude] = location.center;
      return stringifyMarker(latitude, longitude, options);
    }

    const pickupMarker = pickupLocation?.features[0]
      ? getVenueMarker(pickupLocation?.features[0], {
          label: "P",
          color: "0xffb200",
        })
      : undefined;

    const deliveryMarker = deliveryLocation?.features[0]
      ? getVenueMarker(deliveryLocation?.features[0], {
          label: "D",
          color: "0x36b352",
        })
      : undefined;

    const driverMarker = driverLocation
      ? stringifyMarker(
          driverLocation.location.latitude,
          driverLocation.location.longitude,
          {
            icon: "https://storage.googleapis.com/superdispatch-carrier-tms-staging/driver-location-64x64.png",
          },
        )
      : undefined;

    return [pickupMarker, deliveryMarker, driverMarker].filter(Boolean);
  }, [pickupLocation, deliveryLocation, driverLocation]);

  return (
    <MapContainer>
      {!!markers?.length && allLocationsLoaded && (
        <img
          alt="Map"
          src={`https://maps.googleapis.com/maps/api/staticmap?${stringifySearchQuery(
            {
              size: "433x243",
              key: API_KEY,
              markers,
            },
            {
              indices: false,
            },
          )}`}
        />
      )}
      {!markers?.length && allLocationsLoaded && (
        <img
          alt="Map"
          src={`https://maps.googleapis.com/maps/api/staticmap?${stringifySearchQuery(
            {
              size: "433x243",
              center: "US",
              key: API_KEY,
            },
            {
              indices: false,
            },
          )}`}
        />
      )}
    </MapContainer>
  );
}
