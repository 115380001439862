import axios from "axios";
import { useQuery } from "react-query";
import { BaseResponse } from "shared/api/api";
import { aiagBolSchema } from "./AIAGOnlineBOLDTO";

interface OnlineBOLAIAGResponse extends BaseResponse {
  data: Record<string, unknown>;
  user_message: string;
}

export function fetchOnlineBOLAIAG(id: string, source?: string | null) {
  return axios
    .get<OnlineBOLAIAGResponse>(`aiag/bol/online/${id}/`, {
      params: { source },
    })
    .then(({ data }) => aiagBolSchema.parse(data.data));
}

export function useOnlineBOLAIAG(id: string, source?: string | null) {
  return useQuery(["onlineBOLAIAG", id], () => fetchOnlineBOLAIAG(id, source));
}
