import { Typography } from "@material-ui/core";
import { useUID } from "@superdispatch/ui";
import { Box } from "@superdispatch/ui-lab";
import { useMemo } from "react";
import styled from "styled-components";

import { AIAGVehicleDTO } from "../data/AIAGOnlineBOLDTO";
import { AIAGDamage } from "./AIAGDamage";
import { VehicleValue } from "./VehicleValue";

const Wrapper = styled(Box)`
  &:not(:last-child) > div {
    border-bottom: 1px solid #dfe0e2;
  }
`;
const Item = styled.span`
  line-height: 18px;
  font-size: 15px;
  display: flex;
  flex-wrap: wrap;
`;
const VehicleTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
`;
const InspectionTitle = styled.h3`
  font-weight: 500;
`;
const NoDamageText = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-top: 15px;
`;
const Space = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  width: 100%;
`;

interface VehicleProps {
  data: AIAGVehicleDTO;
}

export const AIAGVehicle = ({ data }: VehicleProps) => {
  const uid = useUID();

  const pickupInspection = useMemo(
    () =>
      data.aiag_inspections.find((inspection) => inspection.step === "pickup"),
    [data.aiag_inspections],
  );
  const deliveryInspection = useMemo(
    () =>
      data.aiag_inspections.find(
        (inspection) => inspection.step === "delivery",
      ),
    [data.aiag_inspections],
  );

  return (
    <Wrapper>
      <Box aria-labelledby={uid} margin="medium">
        <VehicleTitle id={uid}>
          {data.year} {data.make} {data.model}
        </VehicleTitle>
        <Item>
          <VehicleValue value={data.vin} label="VIN:" />
          <VehicleValue value={data.color} label="Color:" />
          <VehicleValue value={data.type} label="Type:" />
        </Item>

        {pickupInspection || deliveryInspection ? (
          <>
            <Box aria-label="pickup damages">
              <InspectionTitle>Pickup Damages</InspectionTitle>

              <Box aria-label="inspection notes" marginBottom="small">
                {data.pickup_inspection_notes ? (
                  <>
                    <Typography color="textSecondary" variant="body1">
                      PICKUP INSPECTION NOTES
                    </Typography>
                    <Typography>{data.pickup_inspection_notes}</Typography>
                  </>
                ) : (
                  <Typography color="textSecondary" variant="body1">
                    NO PICKUP INSPECTION NOTES
                  </Typography>
                )}
              </Box>

              {pickupInspection ? (
                pickupInspection.damages.map((damage, i) => (
                  <AIAGDamage {...damage} key={i} />
                ))
              ) : (
                <NoDamageText>No Damages</NoDamageText>
              )}
            </Box>

            <Space height={10} />

            <Box aria-label="delivery damages">
              <InspectionTitle>Delivery Damages</InspectionTitle>

              <Box aria-label="inspection notes" marginBottom="small">
                {data.delivery_inspection_notes ? (
                  <>
                    <Typography color="textSecondary" variant="body1">
                      DELIVERY INSPECTION NOTES
                    </Typography>
                    <Typography>{data.delivery_inspection_notes}</Typography>
                  </>
                ) : (
                  <Typography color="textSecondary" variant="body1">
                    NO DELIVERY INSPECTION NOTES
                  </Typography>
                )}
              </Box>

              {deliveryInspection ? (
                deliveryInspection.damages.map((damage, i) => (
                  <AIAGDamage {...damage} key={i} />
                ))
              ) : (
                <NoDamageText>No Damages</NoDamageText>
              )}
            </Box>
          </>
        ) : (
          <NoDamageText>No Damages</NoDamageText>
        )}
      </Box>
    </Wrapper>
  );
};
