import { useMemo } from "react";

export type InspectionStep = "delivery" | "pickup";

export interface SignatureToken {
  step: InspectionStep;
  token: string;
}

export function useSignatureToken(locationSearch: string) {
  const token = useMemo<SignatureToken | null>(() => {
    const params = new URLSearchParams(locationSearch);
    const pickupTokenParam = params.get("pickup_touchless_signature_token");
    const deliveryTokenParam = params.get("delivery_touchless_signature_token");

    if (pickupTokenParam) {
      return {
        token: pickupTokenParam,
        step: "pickup",
      };
    }

    if (deliveryTokenParam) {
      return {
        token: deliveryTokenParam,
        step: "delivery",
      };
    }

    return null;
  }, [locationSearch]);

  return token;
}
