import { Inline, Stack } from "@superdispatch/ui";
import { TextBox } from "@superdispatch/ui-lab";
import { useFlag } from "shared/utils/features";

interface GeoTagInfoProps {
  date: string;
  address: string | null;
  label: string;
}

export function GeoTagInfo({ address, date, label }: GeoTagInfoProps) {
  const isGeoTagEnabled = useFlag("geo_tag");
  return (
    <Stack space="none">
      <Inline verticalAlign="bottom" space="xxsmall">
        <TextBox variant="caption" color="primary">
          {date}
        </TextBox>

        {!isGeoTagEnabled && (
          <TextBox variant="caption" color="secondary">
            · {label}
          </TextBox>
        )}
      </Inline>

      {isGeoTagEnabled && (
        <Inline>
          <TextBox variant="caption" color={address ? "primary" : "secondary"}>
            {address ? address : "Location not shared"}
          </TextBox>
          <TextBox variant="caption" color="secondary">
            · {label}
          </TextBox>
        </Inline>
      )}
    </Stack>
  );
}
