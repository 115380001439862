import { Inline, Stack } from "@superdispatch/ui";
import { TextBox, DescriptionItem } from "@superdispatch/ui-lab";
import { Business } from "@material-ui/icons";

interface CarrierDetailsProps {
  name: string;
  email: string;
  phone: string;
}

export function CarrierDetails({ name, email, phone }: CarrierDetailsProps) {
  return (
    <Inline>
      <Stack space="xxsmall">
        <DescriptionItem icon={<Business color="action" fontSize="small" />}>
          <TextBox
            color="primary"
            aria-label="Carrier details name"
            variant="heading-4"
          >
            {name}
          </TextBox>
        </DescriptionItem>
        <DescriptionItem inset={true}>
          <TextBox
            aria-label="Carrier details phone"
            variant="body"
            color="secondary"
          >
            {phone}
          </TextBox>
        </DescriptionItem>
        <DescriptionItem inset={true}>
          <TextBox
            aria-label="Carrier details email"
            variant="body"
            color="secondary"
          >
            {email}
          </TextBox>
        </DescriptionItem>
      </Stack>
    </Inline>
  );
}
