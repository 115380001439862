import { Loader } from "@googlemaps/js-api-loader";
import { useEffect, useState } from "react";

import { logWarning } from "./monitoring";

export const API_KEY = "AIzaSyCIcGxVSXhA8PuNNm7oJziUACtGWoOWZEY";

const loader = new Loader({
  apiKey: API_KEY,
  version: "weekly",
  libraries: ["places"],
});

export function useGoogleMaps(): typeof google.maps | undefined {
  const [maps, setMaps] = useState<typeof google.maps | undefined>(
    getGoogleMaps,
  );
  useEffect(() => {
    loadGoogleMaps(setMaps);
  }, []);
  return maps;
}

export function loadGoogleMaps(
  setMaps: (maps: typeof google.maps | undefined) => void,
) {
  void loader
    .load()
    .then((google) => {
      setMaps(google.maps);
    })
    .catch((e) => {
      logWarning("Failed to Load GoogleMaps", { error: e });
    });
}

function getGoogleMaps(): undefined | typeof google.maps {
  return typeof google == "object" && typeof google.maps == "object"
    ? google.maps
    : undefined;
}

export interface GeocoderError extends Error {
  code: google.maps.GeocoderStatus;
}

export interface MarkerOptions {
  label?: string;
  color?: string;
  icon?: string;
  anchor?: string;
}

export function stringifyMarker(
  latitude: number | null,
  longitude: number | null,
  options: MarkerOptions,
) {
  const stringifiedOptions = Object.entries(options)
    .map(([key, value]) => `${key}:${value}`)
    .join("|");

  return `${stringifiedOptions}|${latitude},${longitude}`;
}

export function stringifyAddressMarker(
  address: string,
  options: {
    label?: string;
    color?: string;
    icon?: string;
  },
) {
  const stringifiedOptions = Object.entries(options)
    .map(([key, value]) => `${key}:${value}`)
    .join("|");

  return `${stringifiedOptions}|${address}`;
}
