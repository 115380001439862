import { vehicleSchema } from "shared/dto/VehicleDTO";
import { z } from "zod";
import { venueSchema } from "shared/dto/VenueDTO";

export type BolDTO = z.infer<typeof bolSchema>;
export const bolSchema = z
  .object({
    vehicles: z.array(vehicleSchema),
    terms: z.string(),
  })
  .merge(venueSchema);
