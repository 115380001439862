import { Card } from "@material-ui/core";
import { Column, Columns, Stack } from "@superdispatch/ui";
import { useEffect } from "react";
import { useRouteMatch } from "react-router";
import { trackBolEvent } from "shared/analytics/BOLAnalytics";
import { Loading } from "shared/components/Loading";
import { NotFound } from "shared/components/NotFound";
import { VenueDTO } from "shared/dto/VenueDTO";
import { LoadDetails } from "shared/eta/load-details/LoadDetails";
import { useSourceQuery } from "shared/utils/useSourceQuery";

import { AIAGVehicle } from "./core/AIAGVehicle";
import { useOnlineBOLAIAG } from "./data/AIAGOnlineBOLAPI";
import { AIAGVehicleDTO } from "./data/AIAGOnlineBOLDTO";

export function AIAGOnlineBOLPage() {
  const match = useRouteMatch<{ id: string }>();
  const source = useSourceQuery();
  const { data, isFetching } = useOnlineBOLAIAG(match.params.id, source);

  useEffect(() => {
    trackBolEvent({
      name: "Viewed Online BOL",
      is_aiag: true,
    });
  }, []);

  if (isFetching && !data) {
    return <Loading />;
  }

  if (!data) {
    return <NotFound />;
  }

  return (
    <Columns space="small" collapseBelow="tablet">
      <Column width="2/5">
        <Stack>
          <LoadDetails
            data={data as VenueDTO}
            isAIAG={true}
            isAIAGWithETA={true}
          />
        </Stack>
      </Column>
      <Column width="3/5">
        {data.vehicles && (
          <Card>
            {data.vehicles.map((vehicle: AIAGVehicleDTO) => (
              <AIAGVehicle data={vehicle} key={vehicle.vin} />
            ))}
          </Card>
        )}
      </Column>
    </Columns>
  );
}
