import { Box } from "@superdispatch/ui-lab";
import { ReactNode } from "react";

import { trackBolEvent } from "../analytics/BOLAnalytics";
import { Footer } from "./Footer";
import { Header } from "./Header";

interface PageLayoutProps {
  children: ReactNode;
}

export function PageLayout({ children }: PageLayoutProps) {
  const onPrint = () => {
    trackBolEvent({ name: "Clicked Print Online BOL Button" });
    //Firefox for android does not support "window.print" https://caniuse.com/mdn-api_window_print
    window.print && window.print();
  };

  return (
    <>
      <Header onPrint={onPrint} hasPrintButton={true} />
      <Box margin={["none", "large"]}>
        <Box maxWidth="1112px" width="100%" paddingTop="xsmall" margin="auto">
          {children}
          <Footer />
        </Box>
      </Box>
    </>
  );
}
