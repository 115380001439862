import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

const Wrapper = styled.div`
  height: calc(100vh - 55px);
  justify-content: center;
  align-items: center;
  display: flex;
`;

export function Loading() {
  return (
    <Wrapper>
      <CircularProgress size={64} />
    </Wrapper>
  );
}
