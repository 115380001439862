import * as analytics from "../utils/analytics";

export type BOLAnalyticsEvent =
  | {
      name: "Viewed Online BOL";
      is_aiag: boolean;
      utm_medium?: string;
      utm_source?: string;
    }
  | {
      name: "Clicked Copy Online BOL Link";
      utm_medium?: string;
      utm_source?: string;
    }
  | {
      name: "Clicked Print Online BOL Button";
      utm_medium?: string;
      utm_source?: string;
    }
  | {
      name: "Clicked Online BOL Video Inspection";
    }
  | {
      name: "Opened Touchless Signature BOL";
      order_status?: string;
      number_of_photos: number;
    };

export function trackBolEvent({ name, ...parameters }: BOLAnalyticsEvent) {
  analytics.trackEvent(name, parameters as Record<string, unknown>);
}
