import axios, { AxiosResponse } from "axios";
import { useQuery, UseQueryOptions } from "react-query";

export type APIQueryOptions<TData> = Omit<
  UseQueryOptions<TData, Error>,
  "queryFn" | "queryKey" | "queryHash" | "queryKeyHashFn" | "isDataEqual"
>;

export interface BaseResponse {
  meta: { code: number; request_id: string };
}

export interface BaseErrorResponse extends BaseResponse {
  error: {
    type?: string;
    user_message?: string;
    dev_message?: string;
    context?: string;
  };
}

axios.interceptors.request.use((config) => {
  config.baseURL = import.meta.env.VITE_APP_API_URL;
  return config;
});

// TODO: Use interceptors instead
export function handleResponse<T extends BaseResponse>({
  data,
}: AxiosResponse<T>) {
  if (!data.meta || (data.meta.code >= 200 && data.meta.code < 400)) {
    return data;
  }

  return Promise.reject(data);
}

interface FeaturesResponse extends BaseResponse {
  data: Record<string, boolean | null | undefined>;
  user_message: string;
}

function fetchFeatures() {
  return axios
    .get<FeaturesResponse>("/features/")
    .then(handleResponse)
    .then(({ data }) => data);
}

export function useFeatures() {
  return useQuery("features", fetchFeatures, {
    refetchInterval: Infinity,
    staleTime: Infinity,
  });
}
