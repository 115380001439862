import { Card, CardContent } from "@material-ui/core";
import {
  Column,
  Columns,
  Stack,
  useCollapseBreakpoint,
  useSnackbarStack,
} from "@superdispatch/ui";
import { Box } from "@superdispatch/ui-lab";
import { useEffect, useMemo, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router";
import { trackBolEvent } from "shared/analytics/BOLAnalytics";
import { Loading } from "shared/components/Loading";
import { NotFound } from "shared/components/NotFound";
import { LoadDetails } from "shared/eta/load-details/LoadDetails";
import { useSignatureToken } from "shared/utils/useSignatureToken";
import { useSourceQuery } from "shared/utils/useSourceQuery";
import useSWR from "swr";

import { DamageCodeDefinitions } from "./core/DamageCodesDefinitions";
import { SignatureSection } from "./core/SignatureSection";
import { Vehicle } from "./core/Vehicle";
import { fetchPhotoCount, useOnlineBOL } from "./data/OnlineBOLAPI";
import { SignaturePage } from "./SignaturePage";

export function OnlineBOLPage() {
  const { addSnackbar } = useSnackbarStack();
  const match = useRouteMatch<{ id: string }>();
  const source = useSourceQuery();
  const { search } = useLocation();
  const { data, isFetching } = useOnlineBOL(match.params.id, source);
  const signatureToken = useSignatureToken(search);
  const [isAllPhotosSynced, setIsAllPhotosSynced] = useState(false);
  const isMobile = useCollapseBreakpoint("sm");

  //TODO: Replace with useQuery
  const { data: photosCount } = useSWR(
    signatureToken ? match.params.id : null,
    (id: string) => signatureToken && fetchPhotoCount(id, signatureToken.step),
    {
      revalidateOnFocus: false,
      refreshInterval: isAllPhotosSynced ? undefined : 5000,
      onSuccess: (response) => {
        if (response && response.ready === response.total) {
          setIsAllPhotosSynced(true);
        }
      },
      compare: (prevResponse, currentResponse) => {
        return (
          !!prevResponse &&
          !!currentResponse &&
          prevResponse.ready === currentResponse.ready
        );
      },
      onError: () => {
        addSnackbar("Error: Couldn't fetch inspection photos count.", {
          variant: "error",
        });
      },
    },
  );

  const showSignatureSection = useMemo(() => {
    if (signatureToken && data) {
      if (signatureToken.step === "pickup" && !data.pickup_signature_refused) {
        return !data.pickup.signature;
      } else if (
        signatureToken.step === "delivery" &&
        !data.delivery_signature_refused &&
        !data.delivery_subject_to_inspection
      ) {
        return !data.delivery.signature;
      }
    }

    return false;
  }, [data, signatureToken]);

  useEffect(() => {
    trackBolEvent({
      name: "Viewed Online BOL",
      is_aiag: false,
    });
  }, []);

  useEffect(() => {
    if (signatureToken && photosCount && data) {
      trackBolEvent({
        name: "Opened Touchless Signature BOL",
        order_status: data.status,
        number_of_photos: photosCount.total,
      });
    }
  }, [signatureToken, photosCount, data]);

  if (isFetching && !data) {
    return <Loading />;
  }

  if (!data) {
    return <NotFound />;
  }

  return (
    <Route path="/:id?(/sign)?">
      <Switch>
        {(!signatureToken ||
          (data.pickup.signature && data.delivery.signature)) && (
          <Redirect from="/:id/sign" to="/:id" />
        )}
        <Route path="/:id" exact={true}>
          <Columns space="small" collapseBelow="tablet">
            <Column width="2/5">
              <Stack space="small">
                <LoadDetails data={data} />
                {!isMobile && <DamageCodeDefinitions />}
              </Stack>
            </Column>
            <Column width="3/5">
              <Stack space="small">
                {data.vehicles &&
                  data.vehicles.map((vehicle, index) => (
                    <Card key={index}>
                      <CardContent>
                        <Vehicle data={vehicle} loadStatus={data.status} />
                      </CardContent>
                    </Card>
                  ))}
                {isMobile && <DamageCodeDefinitions />}
              </Stack>
            </Column>
          </Columns>
          {showSignatureSection && photosCount && (
            <Box position="sticky" bottom={0}>
              <SignatureSection
                readyPhotos={photosCount.ready}
                totalPhotos={photosCount.total}
                orderStatus={data.status}
              />
            </Box>
          )}
        </Route>
        <Route path="/:id/sign">
          <SignaturePage
            loadId={data.load_id}
            terms={data.terms}
            orderStatus={data.status}
          />
        </Route>
      </Switch>
    </Route>
  );
}
