import { z } from "zod";

export type EtaDateDTO = z.infer<typeof etaDateSchema>;
export const etaDateSchema = z.object({
  from: z.string().nullable(),
  until: z.string().nullable(),
});

export const etaSchema = z.object({
  updated_at: z.string().nullable(),
  estimated_date: etaDateSchema,
  notes: z.string().nullable(),
});
