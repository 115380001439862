import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Color, Stack, useCollapseBreakpoint } from "@superdispatch/ui";
import { Button } from "@superdispatch/ui-lab";
import { useState } from "react";
import { VenueDTO } from "shared/dto/VenueDTO";
import styled from "styled-components";

import { LoadDriverLocationDTO } from "../data/ETADTO";
import { ETADetails } from "./ETADetails";
import { ETAProgress } from "./ETAProgress";

export const ShowMoreButton = styled(Button)`
  color: ${Color.Blue300};
  padding: 0;

  svg {
    font-size: 18px;
  }
`;

export interface ETAContainerProps {
  venue: VenueDTO;
  driverLocation: LoadDriverLocationDTO | undefined;
}

export function ETAContainer({ venue, driverLocation }: ETAContainerProps) {
  const collapsable = useCollapseBreakpoint("sm");
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Stack space="small">
      <ETADetails venue={venue} driverLocation={driverLocation} />
      {collapsable && (
        <ShowMoreButton
          variant="text"
          startIcon={isExpanded ? <ExpandLess /> : <ExpandMore />}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded ? "Show less" : "Show more"}
        </ShowMoreButton>
      )}
      {(!collapsable || isExpanded) && <ETAProgress venue={venue} />}
    </Stack>
  );
}
