import { z } from "zod";

export const locationSchema = z.object({
  latitude: z.number().nullable(),
  longitude: z.number().nullable(),
  time: z.string().nullable(),
});

export type DriverLocationDTO = z.infer<typeof driverLocationSchema>;
export const driverLocationSchema = z.object({
  vehicles: z.string().array(),
  location: locationSchema,
});

export type SingleDriverLocationDTO = z.infer<
  typeof singleDriverLocationSchema
>;
export const singleDriverLocationSchema = z.object({
  location: locationSchema,
});

export type LoadDriverLocationDTO = z.infer<typeof loadDriverLocationSchema>;
export const loadDriverLocationSchema = z.object({
  driver: singleDriverLocationSchema.nullable(),
});
