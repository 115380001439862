import { ExpandMore } from "@material-ui/icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { Inline, Stack } from "@superdispatch/ui";
import { TextBox } from "@superdispatch/ui-lab";

const DAMAGE_CODES = [
  ["BR", "Broken"],
  ["CH", "Chipped"],
  ["CR", "Cracked"],
  ["D", "Dented"],
  ["F", "Faded"],
  ["FF", "Foreign Fluid"],
  ["FT", "Flat Tire"],
  ["G", "Gouge"],
  ["HD", "Hail Damage"],
  ["LC", "Loose Contents"],
  ["M", "Missing"],
  ["MD", "Major Damage"],
  ["MS", "Multiple Scratches"],
  ["O", "Other"],
  ["PC", "Paint Chip"],
  ["R", "Rubbed"],
  ["RU", "Rust"],
  ["S", "Scratched"],
  ["SC", "Scuffed"],
];

export const DamageCodeDefinitions = () => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <TextBox variant="heading-4">Damage Code Definitions</TextBox>
      </AccordionSummary>
      <AccordionDetails>
        <Stack space="medium">
          <Stack>
            {DAMAGE_CODES.map(([key, value]) => (
              <TextBox key={key}>
                {key} - {value}
              </TextBox>
            ))}
          </Stack>

          <Stack>
            <Inline>
              <img
                width="18px"
                src="https://superdispatch-staging.s3.amazonaws.com/static/msd/images/damages/CR-0.png"
                alt="CR"
              />
              <TextBox>Circles - pickup damages</TextBox>
            </Inline>
            <Inline>
              <img
                width="18px"
                src="https://superdispatch-staging.s3.amazonaws.com/static/msd/images/damages/CR-1.png"
                alt="CR"
              />
              <TextBox>Rounded rectangles - delivery damages</TextBox>
            </Inline>
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
