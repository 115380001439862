import "reflect-metadata";

import { ThemeProvider } from "@superdispatch/ui";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { AIAGOnlineBOLPage } from "./aiag-bol/AIAGOnlineBOLPage";
import { OnlineBOLPage } from "./bol/OnlineBOLPage";
import { useFeatures } from "./shared/api/api";
import { PageLayout } from "./shared/layout/PageLayout";
import { ErrorBoundary } from "./shared/layout/ErrorBoundary";

function App() {
  const { isFetching } = useFeatures();

  if (isFetching) {
    return null;
  }

  return (
    <ErrorBoundary>
      <ThemeProvider>
        <Router>
          <PageLayout>
            <Switch>
              <Route path="/aiag/:id?" exact={true}>
                <AIAGOnlineBOLPage />
              </Route>
              <Route path="/:id?">
                <OnlineBOLPage />
              </Route>
            </Switch>
          </PageLayout>
        </Router>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
