import { Color } from "@superdispatch/ui";
import { createGlobalStyle } from "styled-components";
import { GeocodeResponse } from "@mapbox/mapbox-sdk/services/geocoding";
import { logError } from "../../utils/monitoring";

//https://developers.google.com/maps/documentation/javascript/examples/overlay-popup#maps_overlay_popup-css
export const GoogleMapsOverlayGlobalStyles = createGlobalStyle`
  .gm-overlay-bubble {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    padding: 4px 8px;
    background: ${Color.Dark400};
    color: ${Color.White};
    border-radius: 8px;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    line-height: 20px;
   }

  .gm-overlay-bubble-anchor {
    position: absolute;
    width: 100%;
    bottom: 8px;
    left: 0;
  }

  .gm-overlay-bubble-anchor::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, 0);
    /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 6px solid ${Color.Dark400};
  }

  .gm-overlay-container {
    cursor: auto;
    height: 0;
    position: absolute;
    width: 200px;
  }
`;

export function createOverlay(location: google.maps.LatLng, label: string) {
  class Overlay extends google.maps.OverlayView {
    position: google.maps.LatLng;
    containerDiv: HTMLDivElement;

    constructor(position: google.maps.LatLng, title: string) {
      super();
      this.position = position;

      const content = document.createElement("div");
      content.innerText = title;

      content.classList.add("gm-overlay-bubble");

      // This zero-height div is positioned at the bottom of the bubble.
      const bubbleAnchor = document.createElement("div");

      bubbleAnchor.classList.add("gm-overlay-bubble-anchor");
      bubbleAnchor.appendChild(content);

      // This zero-height div is positioned at the bottom of the tip.
      this.containerDiv = document.createElement("div");
      this.containerDiv.classList.add("gm-overlay-container");
      this.containerDiv.appendChild(bubbleAnchor);

      // Optionally stop clicks, etc., from bubbling up to the map.
      Overlay.preventMapHitsAndGesturesFrom(this.containerDiv);
    }

    /** Called when the popup is added to the map. */
    onAdd() {
      this.getPanes()?.floatPane.appendChild(this.containerDiv);
    }

    /** Called when the popup is removed from the map. */
    onRemove() {
      if (this.containerDiv.parentElement) {
        this.containerDiv.parentElement.removeChild(this.containerDiv);
      }
    }

    /** Called each frame when the popup needs to draw itself. */
    draw() {
      const divPosition = this.getProjection().fromLatLngToDivPixel(
        this.position,
      );
      if (!divPosition) {
        return;
      }

      // Hide the popup when it is far out of view.
      const display =
        Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
          ? "block"
          : "none";

      if (display === "block") {
        this.containerDiv.style.left = `${divPosition.x}px`;
        this.containerDiv.style.top = `${divPosition.y}px`;
      }

      if (this.containerDiv.style.display !== display) {
        this.containerDiv.style.display = display;
      }
    }
  }

  return new Overlay(location, label);
}

interface MarkLocationOptions {
  maps: typeof google.maps;
  map: google.maps.Map;
  bounds: google.maps.LatLngBounds;
}

export function markLocation(
  response: GeocodeResponse,
  label: string,
  { maps, map, bounds }: MarkLocationOptions,
) {
  if (!response.features[0]) {
    return;
  }

  try {
    const [lng, lat] = response.features[0].center;
    const location = new maps.LatLng(lat, lng);
    bounds.extend(location);
    map.fitBounds(bounds);

    const overlay = createOverlay(location, label);
    overlay.setMap(map);
    return overlay;
  } catch (e) {
    logError(e, "googleMapsOverlays", {
      extraInfo: { address: response.query.join(",") },
    });
  }
}
