import { ReactNode } from "react";
import type { ImageFit } from "yet-another-react-lightbox";

export function parsePhotoSlide(
  image: string,
  title: ReactNode,
  isVehicleNotDetected: boolean,
) {
  return {
    src: image,
    alt: "",
    imageFit: "contain" as ImageFit,
    title: isVehicleNotDetected ? title : undefined,
  };
}
