import { DateTime, Duration, Zone } from "luxon";

export const CST = "CST";

export function formatDate(
  date: string,
  timeZone: string | Zone = CST,
  format = "MMM d, h:mm a",
) {
  return DateTime.fromISO(date).setZone(timeZone).toFormat(format);
}

export function formatScheduledDate(date: string, timeZone?: string | Zone) {
  return formatDate(date, timeZone, "MMM d");
}

export function formatCSTDate(date: string, timeZone?: string | Zone) {
  return `${formatDate(date, timeZone)} CST`;
}

export function formatDuration(seconds: number) {
  let duration = Duration.fromObject({ seconds });

  let formatted = duration.toFormat("mm:ss");

  return formatted;
}
