import styled from "styled-components";

import { DamageDTO } from "../data/AIAGOnlineBOLDTO";
import { PhotoGridAIAG } from "./photos/PhotoGridAIAG";

const Wrapper = styled.div`
  margin-bottom: 20px;
`;
const Description = styled.div`
  margin-bottom: 5px;
`;
const DamageCode = styled.span`
  font-weight: 500;
  margin-right: 5px;
  font-size: 18px;
`;

export function AIAGDamage({ area, severity, type, photos }: DamageDTO) {
  return (
    <Wrapper>
      <Description>
        <DamageCode>{area.code}</DamageCode>
        <DamageCode>{type.code}</DamageCode>
        <DamageCode>{severity.code}</DamageCode>
        <span>{area.description}</span> <span>{type.description}</span>
        {" - "}
        <span>{severity.description}</span>
      </Description>
      <PhotoGridAIAG photos={photos} />
    </Wrapper>
  );
}
