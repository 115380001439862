import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Box, LinearProgress, Typography } from "@material-ui/core";
import { Color } from "@superdispatch/ui";
import { ComponentType } from "react";

interface AppProgressProps {
  total: number;
  value: number;
}

export const Progress: ComponentType<AppProgressProps> = ({ total, value }) => {
  return (
    <Box>
      <Box aria-label="Photos count" display="flex" px={3} py={1}>
        {total === value ? (
          <>
            <Box marginRight="auto">
              <Typography>All photos are ready for review</Typography>
            </Box>
            <Box color={Color.Green300}>
              <CheckCircleIcon color="inherit" fontSize="small" />
            </Box>
          </>
        ) : (
          <>
            <Box marginRight="auto">
              <Typography>Getting photos</Typography>
            </Box>
            <Typography>
              <strong>{value}</strong> of <strong>{total}</strong>
            </Typography>
          </>
        )}
      </Box>
      <LinearProgress variant="determinate" value={(value / total) * 100} />
    </Box>
  );
};
