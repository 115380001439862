import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Video from "yet-another-react-lightbox/plugins/video";
import type { Slide } from "yet-another-react-lightbox";
import { Lightbox } from "yet-another-react-lightbox";
import styled from "styled-components";
import { VideoDTO } from "shared/dto/VideoDTO";
import { trackBolEvent } from "shared/analytics/BOLAnalytics";

const LighboxImage = styled(Lightbox)`
  .yarl__container {
    background-color: rgba(0, 0, 0, 0.85);
  }
  .yarl__slide_title_container {
    background: transparent;
  }
`;

interface LightboxGalleryProps {
  slides: Slide[];
  currentSlideIndex: number;
  setCurrentSlideIndex: (index: number) => void;
}

export function LightboxGallery({
  slides,
  currentSlideIndex,
  setCurrentSlideIndex,
}: LightboxGalleryProps) {
  return (
    <LighboxImage
      plugins={[Fullscreen, Zoom, Captions, Counter, Video, Thumbnails]}
      captions={{ descriptionTextAlign: "start" }}
      on={{
        view: (e) => {
          setCurrentSlideIndex(e.index);
          const isVideo = slides[e.index]?.type === "video";
          if (isVideo) {
            trackBolEvent({
              name: "Clicked Online BOL Video Inspection",
            });
          }
        },
      }}
      thumbnails={{
        width: 96,
        height: 72,
      }}
      video={{
        autoPlay: true,
        controlsList: "nodownload",
      }}
      counter={{
        container: { style: { top: 0, bottom: "unset" } },
        separator: "of",
      }}
      zoom={{
        scrollToZoom: false,
        zoomInMultiplier: 2,
        maxZoomPixelRatio: 1000,
      }}
      index={currentSlideIndex}
      slides={slides}
      open={currentSlideIndex >= 0}
      close={() => {
        setCurrentSlideIndex(-1);
      }}
      controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
    />
  );
}

export function parseVideo(video: VideoDTO) {
  return {
    type: "video" as const,
    width: 1280,
    height: 720,
    poster: video.thumbnail_url,
    autoplay: true,
    sources: [
      {
        src: video.url,
        type: "video/mp4",
      },
    ],
  };
}
