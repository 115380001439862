import { ComponentType } from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  position: relative;
  padding-left: 24px;
`;

const StyledGutter = styled.div`
  display: block;
  position: absolute;
  top: 5px;
  left: 3px;
  bottom: 5px;
  width: 1px;
  background: repeating-linear-gradient(
    #979ca4,
    #979ca4 3px,
    transparent 3px,
    transparent 5px
  );

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: -0.93mm;
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 50%;
  }

  &:before {
    top: 0;
    background: #ffb200;
  }

  &:after {
    bottom: 0;
    background: #36b352;
  }
`;

export const VenuePath: ComponentType = ({ children }) => {
  return (
    <StyledContainer>
      <StyledGutter />
      {children}
    </StyledContainer>
  );
};
