import { NullableDateInput, parseDate } from "@superdispatch/dates";
import { DateTime, Duration, DurationObject } from "luxon";

export function isDateWithin(
  dateInput: NullableDateInput,
  duration: DurationObject,
) {
  const date = parseDate(dateInput);
  const borderDate = DateTime.local().minus(Duration.fromObject(duration));
  return date >= borderDate;
}
