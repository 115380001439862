import { z } from "zod";

export type VideoDTO = z.infer<typeof videoSchema>;
export const videoSchema = z.object({
  guid: z.string(),
  url: z.string().nullable().default(""),
  type: z.enum(["pickup", "delivery"]),
  thumbnail_url: z.string().nullable().default(""),
  taken_at: z.string().nullable().optional(),
  latitude: z.number().nullable().optional(),
  longitude: z.number().nullable().optional(),
  duration_in_seconds: z.number().nullable().optional(),
});
