import { Typography } from "@material-ui/core";
import { FormattedDate } from "@superdispatch/dates";
import { Inline, Stack } from "@superdispatch/ui";
import { Box, TextBox } from "@superdispatch/ui-lab";
import { VenueDTO } from "shared/dto/VenueDTO";
import { formatCSTDate } from "shared/utils/formatDate";

import { useETADates } from "../core/RelativeDates";
import { isDelivered, isPickedUp } from "../core/Status";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "../core/Timeline";
import { GeoTagInfo } from "./GeoTagInfo";
export interface ETAProgressProps {
  venue: VenueDTO;
}

export function ETAProgress({ venue }: ETAProgressProps) {
  const { from, until } = venue.eta.estimated_date;
  const isETAAvailable = from !== null && until !== null;
  const date = useETADates(from, until);

  return (
    <Timeline>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="primary" />
          <TimelineConnector
            color={isPickedUp(venue.status) ? "primary" : undefined}
          />
        </TimelineSeparator>
        <TimelineContent>
          <Box aria-labelledby="accepted" paddingBottom="small">
            <Stack space="xxsmall">
              <Typography id="accepted" variant="body1">
                Accepted
              </Typography>
              <TextBox variant="caption" color="secondary">
                {formatCSTDate(venue.created_at)}
              </TextBox>
            </Stack>
          </Box>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            color={isPickedUp(venue.status) ? "primary" : undefined}
          />
          <TimelineConnector
            color={isDelivered(venue.status) ? "primary" : undefined}
          />
        </TimelineSeparator>
        <TimelineContent>
          <Box aria-labelledby="picked-up" paddingBottom="small">
            <Stack space="xxsmall">
              <Typography id="picked-up" variant="body1">
                Picked Up
              </Typography>
              {venue.pickup.scheduled_date && (
                <Inline space="xxsmall">
                  <TextBox variant="caption" color="primary">
                    <FormattedDate
                      date={venue.pickup.scheduled_date}
                      variant="ShortDate"
                    />
                  </TextBox>
                  <TextBox variant="caption" color="secondary">
                    · Scheduled
                  </TextBox>
                </Inline>
              )}
              {venue.status === "New" && isETAAvailable ? (
                <Inline space="xxsmall">
                  <TextBox variant="caption" color="primary">
                    {date}
                  </TextBox>
                  <TextBox variant="caption" color="secondary">
                    · ETA
                  </TextBox>
                </Inline>
              ) : null}

              {isPickedUp(venue.status) && venue.pickup.actual_date && (
                <GeoTagInfo
                  date={formatCSTDate(venue.pickup.actual_date)}
                  address={venue.pickup_driver_address}
                  label="Picked Up"
                />
              )}
            </Stack>
          </Box>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            color={isDelivered(venue.status) ? "primary" : undefined}
          />
        </TimelineSeparator>
        <TimelineContent>
          <Box aria-labelledby="delivered" paddingBottom="none">
            <Stack space="xxsmall">
              <Typography id="delivered" variant="body1">
                Delivered
              </Typography>
              {venue.delivery.scheduled_date && (
                <Inline space="xxsmall">
                  <TextBox variant="caption" color="primary">
                    <FormattedDate
                      date={venue.delivery.scheduled_date}
                      variant="ShortDate"
                    />
                  </TextBox>
                  <TextBox variant="caption" color="secondary">
                    · Scheduled
                  </TextBox>
                </Inline>
              )}

              {venue.delivery.actual_date && (
                <GeoTagInfo
                  date={formatCSTDate(venue.delivery.actual_date)}
                  address={venue.delivery_driver_address}
                  label="Delivered"
                />
              )}
            </Stack>
          </Box>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
