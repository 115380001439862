import { z } from "zod";
import { venueSchema } from "shared/dto/VenueDTO";

export const damagePropertySchema = z.object({
  code: z.string(),
  description: z.string(),
});

export type DamagePhotoDTO = z.infer<typeof damagePhotoSchema>;
export const damagePhotoSchema = z.object({
  original_photo_url: z.string(),
  photo_thumbnail_url: z.string(),
});

export type DamageDTO = z.infer<typeof damageSchema>;
export const damageSchema = z.object({
  area: damagePropertySchema,
  type: damagePropertySchema,
  severity: damagePropertySchema,
  photos: z.array(damagePhotoSchema),
});

export const aiagInspectionSchema = z.object({
  step: z.enum(["pickup", "delivery"]),
  damages: z.array(damageSchema),
});

export type AIAGVehicleDTO = z.infer<typeof aiagVehicleSchema>;
export const aiagVehicleSchema = z.object({
  year: z.number().nullable(),
  make: z.string().nullable(),
  model: z.string().nullable(),
  color: z.string().nullable(),
  type: z.string().nullable(),
  vin: z.string().nullable(),
  aiag_inspections: z.array(aiagInspectionSchema),
  pickup_inspection_notes: z.string().nullable(),
  delivery_inspection_notes: z.string().nullable(),
});

export type AIAGBOLDTO = z.infer<typeof aiagBolSchema>;
export const aiagBolSchema = z
  .object({
    vehicles: z.array(aiagVehicleSchema),
  })
  .merge(venueSchema);
