import styled from "styled-components";

const Text = styled.span`
  color: #4d4d4d;
  font-weight: 300;
  margin-right: 5px;
  margin-bottom: 6px;
  font-size: 15px;
`;
const TextValue = styled.span`
  margin-right: 10px;
  font-size: 15px;
  color: #000000;
  font-weight: 400;
`;

export interface VehicleValueTypes {
  value: string | null;
  label: string;
}

export const VehicleValue = ({ value, label }: VehicleValueTypes) =>
  value ? (
    <Text>
      {label} <TextValue>{value}</TextValue>
    </Text>
  ) : null;
