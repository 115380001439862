import { Divider } from "@material-ui/core";
import { Column, Columns, Inline, Stack, useUID } from "@superdispatch/ui";
import { Box, TextBox } from "@superdispatch/ui-lab";
import _ from "lodash";
import { useMemo } from "react";
import { VehicleDTO } from "shared/dto/VehicleDTO";
import { LoadStatus } from "shared/dto/VenueDTO";
import { isPickedUp } from "shared/eta/core/Status";
import { InspectionEmptyIcon } from "shared/eta/icons/InspectionEmptyIcon";

import { LooseItems } from "./LooseItems";
import { PhotoGrid } from "./PhotoGrid";

interface InspectionProps {
  data: VehicleDTO;
  type: "pickup" | "delivery";
  sameAsPickup?: boolean;
}

function Inspection({ data, type, sameAsPickup }: InspectionProps) {
  const uid = useUID();
  let title = "Delivery Inspection";
  let odometerValue = data.delivery_odometer_reading;
  let notes = data.delivery_inspection_notes;

  if (type === "pickup") {
    title = "Pickup Inspection";
    odometerValue = data.odometer_reading;
    notes = data.pickup_inspection_notes;
  }

  const inspection = useMemo(
    () => data.inspections.find((i) => i.type === type),
    [data, type],
  );

  const photos = useMemo(
    () => data.photos.filter((photo) => photo.type === type),
    [data, type],
  );

  const videos = useMemo(() => {
    if (data.videos && data.videos.length > 0) {
      return data.videos.filter((video) => video.type === type);
    }
    return [];
  }, [data, type]);

  if (!inspection && photos.length === 0 && videos.length === 0) {
    return (
      <Stack aria-labelledby={uid} space="medium">
        <TextBox id={uid} variant="heading-5">
          {title}
        </TextBox>
        <Stack space="small" align="center">
          <InspectionEmptyIcon />
          <TextBox color="secondary">
            This section shows inspection details once they are added
          </TextBox>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack aria-labelledby={uid} space="small">
      <Stack>
        <TextBox id={uid} variant="heading-5">
          {title}
        </TextBox>
        {!!odometerValue && (
          <Inline aria-label="odometer">
            <TextBox color="secondary">Odometer:</TextBox>
            <TextBox> {odometerValue}</TextBox>
          </Inline>
        )}
        {!!notes && (
          <Inline aria-label="inspection notes">
            <TextBox color="secondary">Inspection Notes:</TextBox>
            <TextBox> {notes}</TextBox>
          </Inline>
        )}
      </Stack>

      <Columns space="small" collapseBelow="desktop">
        <Column aria-label="photos" width="1/2">
          <PhotoGrid data={data} photos={photos} videos={videos} type={type} />
        </Column>
        <Column aria-label="inspections" width="1/2">
          {sameAsPickup ? (
            <TextBox color="secondary">Same as Pickup</TextBox>
          ) : (
            inspection && <LooseItems looseItems={inspection} />
          )}
        </Column>
      </Columns>
    </Stack>
  );
}

interface VehicleProps {
  data: VehicleDTO;
  loadStatus?: LoadStatus;
}

export const Vehicle = ({ data, loadStatus }: VehicleProps) => {
  const uid = useUID();

  const pickupInspections = data.inspections.find(
    (inspection) => inspection.type === "pickup",
  );

  const deliveryInspections = data.inspections.find(
    (inspection) => inspection.type === "delivery",
  );

  const inspectionsAreEqual = useMemo(() => {
    if (!pickupInspections || !deliveryInspections) {
      return false;
    }

    const { type: pickupType, ...pickupValues } = pickupInspections;
    const { type: deliveryType, ...deliveryValues } = deliveryInspections;

    return _.isEqual(pickupValues, deliveryValues);
  }, [pickupInspections, deliveryInspections]);

  return (
    <Box aria-labelledby={uid} padding="xsmall">
      <Stack space="small">
        <Stack space="xxsmall">
          <Inline verticalAlign="bottom">
            <TextBox id={uid} variant="heading-4">
              {data.year} {data.make} {data.model}
            </TextBox>
            <TextBox color="secondary">
              {data.color} {data.type}
            </TextBox>
          </Inline>
          <TextBox color="secondary">{data.vin}</TextBox>
          {data.lot_number && (
            <TextBox color="secondary">Lot #: {data.lot_number}</TextBox>
          )}
        </Stack>

        <Divider />
        <Inspection data={data} type="pickup" />

        {(isPickedUp(loadStatus) || deliveryInspections) && (
          <>
            <Divider />
            <Inspection
              data={data}
              type="delivery"
              sameAsPickup={inspectionsAreEqual}
            />
          </>
        )}
      </Stack>
    </Box>
  );
};
