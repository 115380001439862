import styled from "styled-components";

const Wrapper = styled.div`
  height: 35px;
  margin-top: 20px;
  font-size: 13px;
  text-align: center;
  color: #2c2c2c;
  flex-shrink: 0;
`;

const Link = styled.a`
  margin-left: 7px;
  color: #3c98f2;
  text-decoration: none;
`;

export const Footer = () => {
  return (
    <Wrapper>
      Powered by
      <Link href="https://www.superdispatch.com"> Super Dispatch </Link>
    </Wrapper>
  );
};
