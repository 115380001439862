import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { Button } from "@superdispatch/ui";
import { ComponentType, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Progress } from "shared/components/Progress";
import * as analytics from "shared/utils/analytics";
import { useResponsive } from "shared/utils/useResponsive";

interface SignatureSectionProps {
  readyPhotos: number;
  totalPhotos: number;
  orderStatus: string | null;
}

export const SignatureSection: ComponentType<SignatureSectionProps> = ({
  readyPhotos,
  totalPhotos,
  orderStatus,
}) => {
  const [loadedAt] = useState(Date.now());
  const [isDialogOpen, setDialogOpen] = useState(false);
  const history = useHistory();
  const { url } = useRouteMatch();
  const { search } = useLocation();
  const r = useResponsive();

  const navigateToSignaturePage = () => {
    history.push(`${url}/sign${search}`);
  };

  const navigate = () => {
    navigateToSignaturePage();
    analytics.track('Clicked "Add Signature"', {
      duration: Math.round((Date.now() - loadedAt) / 1000),
    });
  };

  const confirm = () => {
    navigateToSignaturePage();
    analytics.track('Clicked "Yes, Continue" on "Add Signature Screen"', {
      order_status: orderStatus,
      number_of_photos: totalPhotos,
    });
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const showConfirmation = () => {
    if (readyPhotos < totalPhotos) {
      setDialogOpen(true);
    }
  };

  return (
    <>
      <Box displayPrint="none">
        <Paper aria-label="Add Signature">
          <Progress value={readyPhotos} total={totalPhotos} />
          <Box display="flex" justifyContent="center" px={3} py={2}>
            <Box width={320}>
              <Button
                color="primary"
                fullWidth={true}
                onClick={
                  readyPhotos < totalPhotos ? showConfirmation : navigate
                }
                variant="contained"
              >
                Add Signature
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={isDialogOpen}
        onClose={closeDialog}
      >
        <DialogContent>
          <Typography
            align={r({ xs: "center", sm: "inherit" })}
            variant={r({ xs: "body1", sm: "body2" })}
          >
            Only {readyPhotos} of {totalPhotos} photos have been downloaded. Are
            you sure you want to continue?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid
            container={true}
            spacing={2}
            justifyContent="flex-end"
            wrap="wrap-reverse"
          >
            <Grid item={true} xs={12} sm="auto">
              <Button color="primary" fullWidth={true} onClick={closeDialog}>
                No
              </Button>
            </Grid>
            <Grid item={true} xs={12} sm="auto">
              <Button
                color="error"
                fullWidth={true}
                onClick={confirm}
                variant="contained"
              >
                Yes, Continue
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
