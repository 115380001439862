import axios from "axios";
import { useQuery } from "react-query";
import {
  BaseErrorResponse,
  BaseResponse,
  handleResponse,
} from "shared/api/api";
import { InspectionStep, SignatureToken } from "shared/utils/useSignatureToken";

import { BolDTO, bolSchema } from "./OnlineBOLDTO";

interface PhotosCountResponse extends BaseResponse {
  data: {
    total: number;
    ready: number;
  };
  user_message: string;
}

export function fetchPhotoCount(id: string, inspectionStep: InspectionStep) {
  return axios
    .get<PhotosCountResponse>(`bol/online/${id}/photos/count`, {
      params: { inspection_step: inspectionStep },
    })
    .then(({ data }) => {
      if (data.meta.code >= 200 && data.meta.code < 400) {
        return data.data;
      }

      return Promise.reject(data);
    });
}

interface TouchlessSignatureData {
  id: string;
  signatureToken: SignatureToken;
  file: Blob;
  signatoryName: string;
}

interface TouchlessSignatureResponse extends BaseResponse {
  message: string;
}

export function sendTouchlessSignature({
  id,
  signatureToken,
  signatoryName,
  file,
}: TouchlessSignatureData) {
  const formData = new FormData();

  formData.set("sign_name", signatoryName);
  formData.set("signature", new File([file], "signature.png"));

  if (signatureToken.step === "delivery") {
    formData.set("delivery_touchless_signature_token", signatureToken.token);
  } else if (signatureToken.step === "pickup") {
    formData.set("pickup_touchless_signature_token", signatureToken.token);
  }

  return axios
    .post<TouchlessSignatureResponse>(`bol/online/${id}/sign/`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(handleResponse);
}

interface OnlineBOLResponse extends BolDTO, BaseErrorResponse {}

export function fetchOnlineBOL(id: string, source?: string | null) {
  return axios
    .get<OnlineBOLResponse>(`bol/online/${id}/`, { params: { source } })
    .then(handleResponse)
    .then((data) => bolSchema.parse(data));
}

export function useOnlineBOL(id: string, source?: string | null) {
  return useQuery(["onlineBOL", id], () => fetchOnlineBOL(id, source));
}
