import createGeocodingClient, {
  GeocodeQueryType,
  GeocodeRequest,
  GeocodeResponse,
} from "@mapbox/mapbox-sdk/services/geocoding";
import { useQuery, UseQueryResult } from "react-query";

const ACCESS_TOKEN =
  "pk.eyJ1Ijoic3VwZXJkaXNwYXRjaCIsImEiOiJjbGhyY29odjQwM2oyM3FsOW8ydmNqYXpkIn0.GeLG-qfNO6w_HZ6Uv4FwMg";

export const standardTypes = [
  "place",
  "region",
  "postcode",
  "address",
] as GeocodeQueryType[];

const geocodingService = createGeocodingClient({
  accessToken: ACCESS_TOKEN,
});

export function useMapboxPredictions(
  query: string | undefined | null,
  types: GeocodeQueryType[] = standardTypes,
  countries: string[] = ["US", "CA", "MX"],
): UseQueryResult<GeocodeResponse, Error> {
  return useQuery(
    ["mapbox", "predictions", { query, types, countries }],
    () =>
      forwardGeocode({
        types,
        countries,
        autocomplete: true,
        query: query as string,
        limit: 1,
      }),
    {
      enabled: !!query,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
}

export function useMapboxReversePredictions(
  query: [number, number] | undefined,
  types: GeocodeQueryType[] = standardTypes,
): UseQueryResult<GeocodeResponse, Error> {
  return useQuery(
    ["mapbox", "predictions", "reverse", { query, types }],
    () =>
      reverseGeocode({
        query: query as [number, number],
        types,
      }),
    {
      enabled: !!query,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
}

export function reverseGeocode(
  request: GeocodeRequest,
): Promise<GeocodeResponse> {
  return geocodingService
    .reverseGeocode({
      mode: "mapbox.places",
      ...request,
    })
    .send()
    .catch(handleError)
    .then((res) => {
      return res.body;
    });
}

export function forwardGeocode(
  request: GeocodeRequest,
): Promise<GeocodeResponse> {
  return geocodingService
    .forwardGeocode({
      mode: "mapbox.places",
      ...request,
    })
    .send()
    .catch(handleError)
    .then((res) => {
      return res.body;
    });
}

function handleError(payload: unknown): never {
  if (payload instanceof Error) {
    throw payload;
  }

  throw Object.assign(new Error(), {
    name: "MapboxError",
    data: payload,
  });
}
