import { Box } from "@superdispatch/ui-lab";
import { round } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { isDateWithin } from "shared/utils/dates";
import { useGoogleMaps } from "shared/utils/map";
import styled from "styled-components";

import locationPin from "../assets/location-pin.svg";
import { mapStyles } from "../core/MapStyles";
import { DriverLocationDTO, SingleDriverLocationDTO } from "../data/ETADTO";
import { GoogleMapsOverlayGlobalStyles, markLocation } from "./Markers";
import { useMapboxPredictions } from "../../utils/mapbox";

export const MapContainer = styled(Box)`
  width: 100%;
  aspect-ratio: 16/9;
`;

export interface MapProps {
  pickupAddress: string | null;
  deliveryAddress: string | null;
  driverLocation:
    | DriverLocationDTO
    | SingleDriverLocationDTO
    | undefined
    | null;
}

export function Map({
  pickupAddress,
  deliveryAddress,
  driverLocation,
}: MapProps) {
  const maps = useGoogleMaps();
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const bounds = useMemo(() => {
    return maps ? new maps.LatLngBounds() : undefined;
  }, [maps]);

  const { data: pickupLocation } = useMapboxPredictions(pickupAddress);
  const { data: deliveryLocation } = useMapboxPredictions(deliveryAddress);

  useEffect(() => {
    if (!maps || !mapRef.current) {
      return;
    }

    const nextMap = new maps.Map(mapRef.current, {
      center: { lat: 39.8097343, lng: -98.5556199 },
      zoom: 3,
      maxZoom: 12,
      mapTypeControl: false,
      disableDefaultUI: true,
      styles: mapStyles,
    });

    setMap(nextMap);

    return () => {
      maps.event.clearInstanceListeners(nextMap);
    };
  }, [maps]);

  useEffect(() => {
    if (!map || !maps || !bounds) {
      return;
    }

    const overlays: google.maps.OverlayView[] = [];
    const options = { maps, map, bounds };

    if (pickupLocation) {
      const overlay = markLocation(pickupLocation, "Pickup", options);
      if (overlay) {
        overlays.push(overlay);
      }
    }

    if (deliveryLocation) {
      const overlay = markLocation(deliveryLocation, "Delivery", options);
      if (overlay) {
        overlays.push(overlay);
      }
    }

    return () => {
      for (const overlay of overlays) {
        overlay.setMap(null);
      }
    };
  }, [pickupLocation, deliveryLocation, map, maps, bounds]);

  useEffect(() => {
    const driverLatitude = driverLocation?.location.latitude;
    const driverLongitude = driverLocation?.location.longitude;

    if (!driverLatitude || !driverLongitude || !maps || !bounds) {
      return;
    }

    const { time } = driverLocation.location;
    if (!isDateWithin(time, { days: 3 })) {
      return;
    }

    const location = new maps.LatLng(
      round(driverLatitude, 2),
      round(driverLongitude, 2),
    );
    bounds.extend(location);
    map?.fitBounds(bounds);

    const icon = {
      url: locationPin,
      size: new google.maps.Size(66, 66),
      anchor: new google.maps.Point(33, 33),
    };

    const marker = new google.maps.Marker({
      map,
      icon,
      position: location,
      title: "Driver",
    });

    return () => {
      marker.setMap(null);
    };
  }, [maps, map, bounds, driverLocation]);

  return (
    <>
      <GoogleMapsOverlayGlobalStyles />
      <MapContainer ref={mapRef} />
    </>
  );
}
