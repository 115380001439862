import { Error, GetApp, PhotoLibrary } from "@material-ui/icons";
import { ImageList, ImageListItem, ImageListItemBar } from "@material-ui/core";
import { Inline, Stack, useSnackbarStack } from "@superdispatch/ui";
import { Box, Button, TextBox } from "@superdispatch/ui-lab";
import { useMemo, useState } from "react";
import { PhotoDTO } from "shared/dto/PhotoDTO";
import { VehicleDTO } from "shared/dto/VehicleDTO";
import { downloadZipWithPhotos } from "shared/utils/zipFiles";
import styled from "styled-components";
import ErrorIcon from "./assets/error-icon.svg";
import { useFlag } from "shared/utils/features";
import { LightboxGallery, parseVideo } from "shared/components/LightboxGallery";
import videoDefaultImage from "./assets/video_default_image.png";
import { PlayIcon } from "./icons/PlayIcon";
import { formatDuration } from "shared/utils/formatDate";
import { VideoDTO } from "shared/dto/VideoDTO";
import { getRenderPhotos, sortPhotos } from "./PhotoGridUtils";
import { parsePhotoSlide } from "shared/utils/mediaUtils";
import type { Slide } from "yet-another-react-lightbox";
import { trackBolEvent } from "shared/analytics/BOLAnalytics";

const DownloadButton = styled(Button)`
  @media print {
    display: none;
  }
`;

const ClickableImageListItem = styled(ImageListItem)`
  cursor: pointer;
  overflow: hidden;
  position: relative;
`;

const StyledImageListItemBar = styled(ImageListItemBar)`
  height: 92px;
`;

const NonVehiclePhotoBagde = styled.div`
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
`;

const VideoContainer = styled.div`
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VideoDuration = styled.div`
  z-index: 2;
  position: absolute;
  bottom: 4px;
  right: 4px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 2px;
  padding: 2px;
  color: white;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VideoWrapper = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NonVehiclePhotoAlert = styled.div`
  position: absolute;
  z-index: 2;
  top: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #192334;
  flex-direction: row;
  padding: 4px 8px;
  gap: 4px;
  border-radius: 8px;
  color: white;
  height: 36px;
  left: 50%;
  transform: translateX(-50%);
`;

interface PhotoGridProps {
  data: VehicleDTO;
  photos: PhotoDTO[];
  videos: VideoDTO[];
  type: "pickup" | "delivery";
}

export const PhotoGrid = ({ data, photos, videos, type }: PhotoGridProps) => {
  const { addSnackbar } = useSnackbarStack();
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(-1);
  const [isDownloading, setIsDownloading] = useState<boolean>();
  const isPhotosInspectionIssuesEnabled = useFlag(
    "ctms_photo_inspection_issues",
  );
  const isVideoInspectionEnabled = useFlag("video_inspection_online_bol");

  const total = photos.length + videos.length;

  const { sortedPhotos, slides, nonVehiclePhotos, photosToRender } =
    useMemo(() => {
      const sorted = sortPhotos(photos);

      return {
        sortedPhotos: sorted,
        slides: sorted.map((photo) => ({
          url: photo.url,
          isVehicleDetected: photo.is_vehicle_detected,
        })),
        nonVehiclePhotos: photos.filter((p) => p.is_vehicle_detected === false),
        photosToRender: getRenderPhotos(
          photos,
          videos,
          type,
          isVideoInspectionEnabled,
        ),
      };
    }, [photos, isVideoInspectionEnabled, videos, type]);

  const handleImageClick = (index: number) => {
    setCurrentSlideIndex(index);
  };

  const handleDownloadPhotos = () => {
    setIsDownloading(true);

    const photoUrlList =
      isVideoInspectionEnabled && videos?.length > 0
        ? [
            ...sortedPhotos.map((photo) => photo.url || ""),
            ...videos.map((video) => video.url || ""),
          ]
        : sortedPhotos.map((photo) => photo.url || "");

    void downloadZipWithPhotos(
      photoUrlList,
      type,
      `${data.make || "unknown make"} ${data.model || "unkown model"}.zip`,
    )
      .catch(() =>
        addSnackbar("An error occurred while creating the archive", {
          variant: "error",
        }),
      )
      .finally(() => {
        setIsDownloading(false);
      });
  };

  const title = (
    <NonVehiclePhotoAlert>
      <img src={ErrorIcon} />
      Possibly Not a Vehicle
    </NonVehiclePhotoAlert>
  );

  const photosList = slides.map((image) =>
    parsePhotoSlide(
      image.url || "",
      title,
      isPhotosInspectionIssuesEnabled && image?.isVehicleDetected === false,
    ),
  );

  const mediaSlides = useMemo(() => {
    const initialPhoto = type === "pickup" ? photosList.slice(0, 1) : [];
    const lastPhotos = type === "pickup" ? photosList.slice(1) : photosList;
    if (isVideoInspectionEnabled && videos && videos.length > 0) {
      return [
        ...initialPhoto,
        ...videos.slice(0, 1).map(parseVideo),
        ...lastPhotos,
        ...videos.slice(1, videos.length).map(parseVideo),
      ];
    }
    return photosList;
  }, [isVideoInspectionEnabled, photosList, videos, type]);

  if (!photos.length && !videos.length) {
    return <TextBox color="secondary">No photos</TextBox>;
  }

  return (
    <Stack space="small">
      {!!nonVehiclePhotos.length && (
        <Box
          backgroundColor="Red50"
          borderRadius="small"
          paddingLeft="xxsmall"
          paddingRight="xxsmall"
          display="inline-block"
        >
          <Inline verticalAlign="center" space="xxsmall">
            <Error fontSize="small" color="error" />
            <TextBox>
              {nonVehiclePhotos.length} non-vehicle photo
              {nonVehiclePhotos.length > 1 ? "s" : ""}
            </TextBox>
          </Inline>
        </Box>
      )}

      <ImageList gap={8} rowHeight={92}>
        {photosToRender.map(
          (
            {
              url,
              thumbnail_url,
              is_vehicle_detected,
              is_video,
              duration_in_seconds,
            },
            index,
          ) => {
            return (
              <ClickableImageListItem
                key={url}
                cols={index === 0 ? 2 : 1}
                rows={index === 0 ? 2 : 1}
                onClick={() => {
                  handleImageClick(index);
                }}
              >
                {is_video && (
                  <>
                    <VideoContainer>
                      <VideoWrapper>
                        <PlayIcon />
                      </VideoWrapper>
                    </VideoContainer>

                    {duration_in_seconds && (
                      <VideoDuration>
                        {formatDuration(duration_in_seconds)}
                      </VideoDuration>
                    )}
                  </>
                )}

                {isPhotosInspectionIssuesEnabled &&
                  is_vehicle_detected === false &&
                  index < 6 && (
                    <NonVehiclePhotoBagde>
                      <img alt="non-vehicle photo icon" src={ErrorIcon} />
                    </NonVehiclePhotoBagde>
                  )}

                <img
                  src={thumbnail_url || url || ""}
                  onError={(e) => {
                    e.currentTarget.src = videoDefaultImage;
                  }}
                />

                {total > 7 && index === 6 && (
                  <StyledImageListItemBar
                    title={
                      <Inline space="xxsmall" horizontalAlign="center">
                        <TextBox color="white" variant="body-semibold">
                          +{total - 7}
                        </TextBox>
                        <PhotoLibrary />
                      </Inline>
                    }
                  />
                )}
              </ClickableImageListItem>
            );
          },
        )}
      </ImageList>

      <DownloadButton
        pending={isDownloading}
        variant="neutral"
        onClick={handleDownloadPhotos}
        startIcon={<GetApp color="action" />}
      >
        Download All {isVideoInspectionEnabled ? "Media" : "Photos"}
      </DownloadButton>

      <LightboxGallery
        slides={mediaSlides as Slide[]}
        currentSlideIndex={currentSlideIndex}
        setCurrentSlideIndex={setCurrentSlideIndex}
      />
    </Stack>
  );
};
