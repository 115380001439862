import axios from "axios";
import { useQuery } from "react-query";
import { APIQueryOptions, BaseResponse } from "shared/api/api";

import { LoadDriverLocationDTO, loadDriverLocationSchema } from "./ETADTO";

interface DriverLocationsResponse extends BaseResponse {
  data: Record<string, unknown>;
  user_message: string;
}

export function fetchDriverLocation(id: string) {
  return axios
    .get<DriverLocationsResponse>(`bol/online/${id}/driver-location/`)
    .then(({ data }) => loadDriverLocationSchema.parse(data.data));
}

export function useDriverLocation(
  id: string,
  options?: APIQueryOptions<LoadDriverLocationDTO>,
) {
  return useQuery(
    ["driverLocation", id],
    () => fetchDriverLocation(id),
    options as Record<string, string>,
  );
}
