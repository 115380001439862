import { useFeatures } from "../api/api";

export type FeatureToggle =
  | "bol_carrier_info"
  | "ctms_photo_inspection_issues"
  | "geo_tag"
  | "new_aiag_online_bol"
  | "new_online_bol_api"
  | "online_bol_eta"
  | "online_bol_static_map"
  | "online_bol_static_map_addresses"
  | "video_inspection_online_bol";

export function useFlag(feature: FeatureToggle) {
  const { data: features } = useFeatures();
  const isEnabled = features?.[feature];

  if (isEnabled === undefined) {
    return true;
  }

  if (isEnabled === null) {
    return false;
  }

  return isEnabled;
}
