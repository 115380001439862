import { saveAs } from "file-saver";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";

import { logWarning } from "./monitoring";

const videoTypes = [".mp4", ".mov", ".avi"];

export async function downloadZipWithPhotos(
  photos: string[],
  folder: string,
  name: string,
) {
  try {
    const photosZip = new JSZip();
    const blobRequests = photos.map((item) => {
      const isVideo = videoTypes.some((type) => item.includes(type));

      if (isVideo) {
        return fetch(item).then((response) => response.blob());
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      return JSZipUtils.getBinaryContent(`${item}?download=1`);
    });

    const blobs = await Promise.all(blobRequests);
    blobs.forEach((blob, index) => {
      const item = photos[index];
      const isVideo = videoTypes.some((type) => item.includes(type));
      const type = isVideo ? ".mp4" : ".png";

      photosZip
        .folder(folder)
        ?.file(`${folder}-${index + 1}${type}`, blob as Blob);
    });

    const content = await photosZip.generateAsync({ type: "blob" });
    saveAs(content, name);
    return undefined;
  } catch (error) {
    logWarning("Failed to generate zip file", { error });
    return Promise.reject(error);
  }
}
