import styled from "styled-components";
import { Box } from "@superdispatch/ui-lab";
import { DriverLocationDTO, SingleDriverLocationDTO } from "../data/ETADTO";
import { stringifySearchQuery } from "../../utils/urlUtils";
import {
  API_KEY,
  stringifyAddressMarker,
  stringifyMarker,
} from "../../utils/map";
import { useMemo } from "react";

export const MapContainer = styled(Box)`
  width: 100%;
  aspect-ratio: 16/9;
`;

export interface MapProps {
  pickupAddress: string | null;
  deliveryAddress: string | null;
  driverLocation:
    | DriverLocationDTO
    | SingleDriverLocationDTO
    | undefined
    | null;
  isDriverLocationFetched: boolean;
}

export function MarkedStaticMap({
  pickupAddress,
  deliveryAddress,
  driverLocation,
  isDriverLocationFetched,
}: MapProps) {
  const allLocationsLoaded = isDriverLocationFetched;

  const markers = useMemo(() => {
    const pickupMarker = pickupAddress
      ? stringifyAddressMarker(pickupAddress, {
          label: "P",
          color: "0xffb200",
        })
      : undefined;

    const deliveryMarker = deliveryAddress
      ? stringifyAddressMarker(deliveryAddress, {
          label: "D",
          color: "0x36b352",
        })
      : undefined;

    const driverMarker = driverLocation
      ? stringifyMarker(
          driverLocation.location.latitude,
          driverLocation.location.longitude,
          {
            anchor: "center",
            icon: "https://storage.googleapis.com/superdispatch-carrier-tms-staging/driver-location-64x64.png",
          },
        )
      : undefined;

    return [pickupMarker, deliveryMarker, driverMarker].filter(Boolean);
  }, [pickupAddress, deliveryAddress, driverLocation]);

  return (
    <MapContainer>
      {!!markers?.length && allLocationsLoaded && (
        <img
          width="100%"
          alt="Map"
          src={`https://maps.googleapis.com/maps/api/staticmap?${stringifySearchQuery(
            {
              size: "433x243",
              key: API_KEY,
              markers,
            },
            {
              indices: false,
            },
          )}`}
        />
      )}
      {!markers?.length && allLocationsLoaded && (
        <img
          width="100%"
          alt="Map"
          src={`https://maps.googleapis.com/maps/api/staticmap?${stringifySearchQuery(
            {
              size: "433x243",
              center: "US",
              key: API_KEY,
            },
            {
              indices: false,
            },
          )}`}
        />
      )}
    </MapContainer>
  );
}
